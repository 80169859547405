import { useText } from "@/v2-ui/text/text.context"
import { type RootTexts } from "@/v2-console-shared/texts"

const CADASTRE_INDUSTRY_TEXTS = {
  "Industry_Company_Header": {
    "Name": "Industry Details"
  },
  "Industry_Company_Count": {
    "Name": "Companies"
  },
  "Industry_Company_WithEmployeesCount": {
    "Name": "Company with Employees"
  },
  "Industry_Company_NumberOfEmployees": {
    "Name": "Total Employees"
  }
}

export type CadastreIndustryTexts = typeof CADASTRE_INDUSTRY_TEXTS & RootTexts
export const useCadastreIndustryText = useText<CadastreIndustryTexts>
export default CADASTRE_INDUSTRY_TEXTS
