import * as React from "react"
import { type VariantProps } from "class-variance-authority"
import cn from "@/v2-ui/utils/utils.cn"
import { badgeCoreVariants } from "@/v2-ui/badge/base/badge.base.constants"

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeCoreVariants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(badgeCoreVariants({ variant }), className)}
        {...props}
      />
    )
  }
)
Badge.displayName = "Badge"

export { Badge }

export { badgeCoreVariants } from "@/v2-ui/badge/base/badge.base.constants"
