import { useText } from "@/v2-ui/text/text.context"
import { type RootTexts } from "@/v2-console-shared/texts"

const CADASTRE_HIERARCHY_TEXTS = {
  "UI_CadastreHierarchy_Header": {
    "Name": "Property Hierarchy"
  },
  "UI_CadastreHierarchy_NothingFound": {
    "Name": "No data found..."
  }

}

export type CadastreHierarchyTexts = typeof CADASTRE_HIERARCHY_TEXTS & RootTexts
export const useCadastreHierarchyText = useText<CadastreHierarchyTexts>
export default CADASTRE_HIERARCHY_TEXTS
