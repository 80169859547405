import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CURRENT_USER_TEXT, { type CurrentUserTexts }
  from "@/v2-console-ui/currentUser/currentUser.text"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"

const CurrentUserMenu = lazy(() => lazyRetry(() => import(
  "@/v2-console/currentUser/menu/CurrentUserMenu"
)))

function CurrentUserMenuSuspender() {
  return (
    <FeatureProvider<CurrentUserTexts>
      code="UI_CurrentUser"
      fallbackTexts={CURRENT_USER_TEXT}
      isLazyLoaded
    >
      <CurrentUserMenu />
    </FeatureProvider>
  )
}

export default CurrentUserMenuSuspender
