import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CADASTRE_BUILDING_TEXTS, { type CadastreBuildingTexts }
  from "@/v2-console-ui/cadastre/building/cadastreBuilding.text"
import type { CadastreModuleContentInputProps } from "@/v2-console/cadastre/cadastre.types"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"
import CadastreModuleLoadingFallback
  from "@/v2-console/cadastre/module/CadastreModuleLoadingFallback"

const CadastreBuilding = lazy(() => lazyRetry(() => import(
  "@/v2-console/cadastre/module/building/CadastreModuleBuilding"
)))

type CadastreModuleBuildingSuspenderProps = CadastreModuleContentInputProps

function CadastreModuleBuildingSuspender(props: CadastreModuleBuildingSuspenderProps) {

  return (
    <FeatureProvider<CadastreBuildingTexts>
      code="Property_Building"
      fallbackTexts={CADASTRE_BUILDING_TEXTS}
      loadingFallback=<CadastreModuleLoadingFallback />
      isLazyLoaded
    >
      <CadastreBuilding
        {...props}
      />
    </FeatureProvider>
  )
}

export default CadastreModuleBuildingSuspender
