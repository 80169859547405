import { useQuery, type QueryHookOptions } from "@apollo/client"
import { useLazyQuery, type LazyQueryHookOptions } from "@apollo/client"
import query from "./cadastre.query.gql"
import type {
  CadastreQuery,
  CadastreQueryVariables
} from "./__types__/cadastre.query"

type LazyOptions = LazyQueryHookOptions<
CadastreQuery,
CadastreQueryVariables
>

export function useCadastreLazyQuery(options?: LazyOptions) {
  return useLazyQuery<
    CadastreQuery,
    CadastreQueryVariables
  >(query, options)
}

type Options = QueryHookOptions<
CadastreQuery,
CadastreQueryVariables
>

function useCadastreQuery(options?: Options) {
  return useQuery<
    CadastreQuery,
    CadastreQueryVariables
  >(query, options)
}

export default useCadastreQuery
