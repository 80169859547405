import type { LayerType } from "@/v2-map-ui/map.types"
import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"

export type GroupLayerProps = WithMeta<__esri.GroupLayerProperties>
export type GroupLayer = WithMeta<__esri.GroupLayer>

export function assertGroupLayer(
  layer: __esri.Layer
): layer is GroupLayer {
  return layer.type === "group"
}

export function assertGroupLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is GroupLayerProps {
  return type === "group"
}
