import type { LayerType } from "@/v2-map-ui/map.types"
import type { Sublayer } from "@/v2-map-ui/layer/mixins/map.layer.mixins.sublayer"
import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"

export type WMSLayerProps = WithMeta<__esri.WMSLayerProperties>
export type WMSLayer = WithMeta<Exclude<__esri.WMSLayer, "sublayers" | "allSublayers">> & {
  sublayers: __esri.Collection<Sublayer>,
  allSublayers: __esri.Collection<Sublayer>
}

export function assertWMSLayer(
  layer: __esri.Layer
): layer is WMSLayer {
  return layer.type === "wms"
}

export function assertWMSLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is WMSLayerProps {
  return type === "wms"
}
