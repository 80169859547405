import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CADASTRE_HIERARCHY_TEXTS, { type CadastreHierarchyTexts }
from "@/v2-console-ui/cadastre/hierarchy/cadastreHierarchy.text"
import type { CadastreModuleContentInputProps }
  from "@/v2-console/cadastre/cadastre.types"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"
import CadastreModuleLoadingFallback
  from "@/v2-console/cadastre/module/CadastreModuleLoadingFallback"

const CadastreHierarchy = lazy(() => lazyRetry(() => import(
  "@/v2-console/cadastre/module/hierarchy/CadastreModuleHierarchy"
)))

type CadastreModuleHierarchySuspenderProps = CadastreModuleContentInputProps

function CadastreModuleHierarchySuspender(props: CadastreModuleHierarchySuspenderProps) {

  return (
    <FeatureProvider<CadastreHierarchyTexts>
      code="Hierarchy_Cadastre"
      fallbackTexts={CADASTRE_HIERARCHY_TEXTS}
      loadingFallback=<CadastreModuleLoadingFallback />
      isLazyLoaded
    >
      <CadastreHierarchy
        {...props}
      />
    </FeatureProvider>
  )
}

export default CadastreModuleHierarchySuspender
