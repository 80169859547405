import type { ReactNode } from "react"
import { useEffect, createContext, useContext } from "react"
import type { AppContextQuery }
  from "@/v2-console/app/context/__types__/app.context.query"
import type { BasemapFragment }
  from "@/v2-console-shared/basemap/__types__/basemap.fragment"
import type { NavLayerFragment }
  from "@/v2-console-shared/navLayer/__types__/navLayer.fragment"

export const EVENTS = new EventTarget()
export const APP_CTX_LOADED_EVENT = "APP_CONTEXT_LOADED"

export type AppContextProviderValue = {
  currentUser?: AppContextQuery["getCurrentUser"],
  events: EventTarget,
  basemaps?: BasemapFragment[],
  navLayers?: NavLayerFragment[]
}

export const DEFAULT_APP_CTX: AppContextProviderValue = {
  currentUser: null,
  events: EVENTS,
  basemaps: null,
  navLayers: null
}

const AppContext = createContext<AppContextProviderValue>({
  currentUser: null,
  events: EVENTS,
  basemaps: null,
  navLayers: null
})

export type AppContextProviderProps = {
  value: AppContextProviderValue,
  children: ReactNode
}

function AppContextProvider(props: AppContextProviderProps) {
  const { value, children } = props
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}

export function useAppEvent<T>(
  eventName: string,
  cb: (e?: CustomEvent<T>) => void
) {
  const { events } = useAppContext()
  useEffect(() => {
    if(!cb || !events || !eventName) return
    events.addEventListener(eventName, cb)
    return () => {
      events.removeEventListener(eventName, cb)
    }
  }, [
    events,
    cb,
    eventName
  ])
}

export default AppContextProvider
