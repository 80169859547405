import { useCallback, useEffect, useRef, useState } from "react"
import type { ThemeConfig } from "@/v2-ui/theme/theme.types"
import {
  AVAILABLE_THEMES,
  AVAILABLE_THEME_MODES,
  THEME_MODES,
  DEFAULT_THEME_CONFIG_BY_MODE
} from "@/v2-ui/theme/theme.constants"
import { baseColors }
  from "@/v2-ui/theme/theme.baseColors"

/**
 * Retrieves the system theme configuration based on the user's OS theme preference.
 * It listens for changes in the `prefers-color-scheme` media query, which indicates whether the user prefers dark mode or light mode.
 *
 * @param e - Optional `MediaQueryList` or `MediaQueryListEvent` object. If not provided, it defaults to the system's current `prefers-color-scheme` value.
 * @returns The appropriate theme configuration based on the system's theme preference.
 */
const getSystemTheme = (e?: MediaQueryList | MediaQueryListEvent) => {
  const MEDIA = "(prefers-color-scheme: dark)"
  if(!e) e = window.matchMedia(MEDIA)
  const isDark = e.matches
  const systemThemeConfig = isDark
    ? DEFAULT_THEME_CONFIG_BY_MODE.dark
    : DEFAULT_THEME_CONFIG_BY_MODE.light
  return systemThemeConfig
}

/**
 * Asserts that a given object is of type `ThemeConfig`.
 *
 * @param obj - The object to check.
 * @returns True if the object matches the `ThemeConfig` structure, otherwise false.
 */
export function assertIsTheme(obj: any): obj is ThemeConfig {
  if(typeof obj !== "object" || obj === null) return false
  if(typeof obj.mode !== "string" || !AVAILABLE_THEME_MODES.includes(obj.mode)) return false
  if(typeof obj.theme !== "string" || !AVAILABLE_THEMES.includes(obj.theme)) return false
  if(typeof obj.radius !== "number") return false
  if(typeof obj.fontSize !== "number") return false
  if(obj.overriddendCssVars && typeof obj.overriddendCssVars !== "object") return false
  return true
}

/**
 * Applies the provided theme configuration to the document's root element.
 *
 * @param themeConfig - The theme configuration to apply, including mode, theme, radius, and overridden CSS variables.
 */
function applyThemeToDocument(themeConfig: ThemeConfig) {

  // @note: could make this target the body or something else also
  const d = document.documentElement

  // remove all other themes
  d.classList.remove(...AVAILABLE_THEME_MODES)

  // Remove any existing theme classes from the html
  const classesToRemove: string[] = []
  for(const className of d.classList) {
    if(/^theme.*/.test(className)) {
      classesToRemove.push(className)
    }
  }
  for(const className of classesToRemove) {
    d.classList.remove(className)
  }

  // remove overridden cssvaruable
  const stylesToRemove: string[] = []
  for(const style of d.style) {
    if(style !== "--radius" && style !== "--font-size" && style.startsWith("--")) {
      stylesToRemove.push(style)
    }
  }
  for(const style of stylesToRemove) {
    d.style.removeProperty(style)
  }

  // add new themeklk
  d.classList.add(themeConfig.mode)
  d.classList.add(`theme-${themeConfig.theme}`)
  d.style.setProperty("--radius", `${themeConfig.radius}rem`)
  d.style.setProperty("--font-size", `${themeConfig.fontSize}px`)

  // add the overriddend css vars
  if(themeConfig.overriddendCssVars) {
    for(const [ key, value ] of Object.entries(themeConfig.overriddendCssVars)) {
      d.style.setProperty(key, value)
    }
  }
}

/**
 * Applies the given theme configuration to the document and stores it in localStorage.
 *
 * @param themeConfig - The theme configuration to apply and store.
 * @param storageKey - The key to use when storing the theme in localStorage. Defaults to `LOCAL_STORAGE_KEY`.
 */
function applyTheme(
  themeConfig: ThemeConfig
  // storageKey: string = LOCAL_STORAGE_KEY
) {
  let resolvedConfig = themeConfig

  // if no theme and no system theme, do nothing
  if(!resolvedConfig) {
    resolvedConfig = getSystemTheme()
  }

  applyThemeToDocument(resolvedConfig)
}

export type UseThemeProps = {
  // storageKey?: string,
  defaultTheme?: ThemeConfig,
  onMount?(theme: ThemeConfig): void,
  onChange?(theme: ThemeConfig): void,
}
/**
 * Hook for managing theme configurations, including applying, storing, and toggling themes.
 *
 * @param props - Properties to configure the theme, including `storageKey`, `defaultTheme`, `onMount`, and `onChange` callbacks.
 * @returns An object containing the current theme, a method to set the theme, and a toggle function for dark mode.
 */
export function useTheme(props: UseThemeProps) {
  const {
    // storageKey = LOCAL_STORAGE_KEY,
    defaultTheme,
    onMount,
    onChange
  } = props

  const isMounted = useRef(false)

  const [ theme, setTheme ] = useState<ThemeConfig>(
    defaultTheme || getSystemTheme()
  )

  useEffect(() => {
    if(!theme || isMounted.current) return

    // check that the theme is valid
    if(!assertIsTheme(theme)) {
      setTheme(getSystemTheme())
      return
    }

    onMount?.(theme)
    isMounted.current = true
  }, [ theme, setTheme, onMount ])

  // make sure the first theme is applied
  useEffect(() => {
    applyTheme(theme)
    onChange?.(theme)
  }, [ theme, onChange ])

  const toggleDarkMode = useCallback(() => {
    const newMode = theme.mode === THEME_MODES.light
      ? THEME_MODES.dark
      : THEME_MODES.light

    const newThemeConfig = {
      ...theme,
      mode: newMode
    }
    setTheme(newThemeConfig)

    onChange?.(newThemeConfig)

  }, [ theme, setTheme, onChange ])

  return {
    theme,
    setTheme,
    toggleDarkMode
  }
}

/**
 * Parses an HSL string and converts it to a CSS-compatible `hsl()` format.
 *
 * @param hslString - The HSL string in the format "H S% L".
 * @returns A CSS-compatible `hsl()` string.
 * @example
 * const color = parseHslToCss("240 10% 3.9")
 * console.log(color) // "hsl(240, 10%, 3.9%)"
 */
export function parseHslToCss(hslString: string): string {
    const [ hue, saturation, lightness ] = hslString.split(" ")
    return `hsl(${hue}, ${saturation}, ${lightness}%)`
}

/**
 * Retrieves the primary HSL color string from the given theme configuration.
 *
 * @param theme - The theme configuration object containing overridden CSS variables
 *                and theme-specific settings.
 *
 * @returns The primary HSL color as a CSS string (e.g., `hsl(210, 100%, 50%)`).
 *          Returns `undefined` if the primary color cannot be determined.
 */
export function getPrimaryHslColorFromTheme(theme: ThemeConfig) {
  const primaryColor = theme.overriddendCssVars?.["--primary"] || baseColors.find(c => c.name === theme.theme)?.activeColor[theme.mode]
  const primaryHslString = parseHslToCss(primaryColor)
  return primaryHslString
}
