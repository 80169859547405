import type { ComponentProps } from "react"
import { forwardRef } from "react"
import { DropdownMenuContent as DropdownMenuBaseContentBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"
import DropdownMenuPortal from "./DropdownMenuPortal"
import { useQuerySelectorLazy } from "../utils/utils.dom"

const DEAFAULT_PORTAL_CONTAINER = "#mount"

const dropdownContentVariations = cva("ui-dropdown-menu-content", {
  variants: {
    size: {
      default: "size-default",
      lg: "size-lg",
      md: "size-md"
    }
  },
  defaultVariants: {
    size: "default"
  }
})

export type DropdownMenuContentProps = ComponentProps<typeof DropdownMenuBaseContentBase>
  & VariantProps<typeof dropdownContentVariations> & {
    portalContainer?: string
  }

function DropdownMenuContent(props: DropdownMenuContentProps, ref) {
  const {
    portalContainer = DEAFAULT_PORTAL_CONTAINER,
    size,
    className,
    ...dropdownMenuBaseContentBaseProps
  } = props
  const portalElem = useQuerySelectorLazy(portalContainer)

  return (
    <DropdownMenuPortal
      container={portalElem}
    >
      <DropdownMenuBaseContentBase
        ref={ref}
        className={dropdownContentVariations({ size, className })}
        {...dropdownMenuBaseContentBaseProps}
      />
    </DropdownMenuPortal>
  )
}

export default forwardRef(DropdownMenuContent)
