import { useEffect, useCallback, useState } from "react"
import { when, whenOnce } from "@arcgis/core/core/reactiveUtils"
import Collection from "@arcgis/core/core/Collection"
import type { ActiveView, Layer } from "@/v2-map-ui/map.types"
import { assertMapView } from "@/v2-map-ui/2d/map.2d.utils"
import { assertSceneView } from "@/v2-map-ui/3d/map.3d.utils"

export function useMapEvent<T>(
  type: string | string[],
  view: ActiveView,
  cb: (
    res: __esri.ViewHit[] | __esri.MapViewViewHit[],
    e: T
  ) => void
) {
  useEffect(() => {
    if(!view) return
    const handler = view.on(type as any, async (e) => {
      const response = await view.hitTest(e as any)
      cb(response.results ?? [], e as any)
    })
    return () => {
      handler.remove()
    }
  }, [
    type,
    view,
    cb
  ])
}

export function useWatchView(
  view: __esri.View,
  path: string | string[],
  cb: __esri.WatchCallback,
  sync?: boolean
) {
  useEffect(() => {
    if(!view || !cb || !path) return
    const watch = view.watch(path, cb, sync)
    return () => {
      watch.remove()
    }
  }, [
    path,
    view,
    cb,
    sync
  ])
}

export type CenterMapGraphics = __esri.Graphic
  | __esri.Graphic[]
  | __esri.Collection<__esri.Graphic>

export function centerMap(
  view: __esri.MapView | __esri.SceneView,
  graphics: CenterMapGraphics
) {
  if(!view) return
  let g: __esri.Graphic[]
  if(graphics instanceof Collection) {
    g = graphics.toArray()
  } else if(Array.isArray(graphics)) {
    g = graphics
  } else {
    g = [ graphics ]
  }

  view.when(() => {
    let target: unknown = graphics

    if(g.length === 1 && g[0]?.geometry?.type === "point") {
      target = {
        target: g[0],
        scale: 200
      }
    }

    try {
      view.goTo(target)
    } catch(error) {
      // eslint-disable-next-line no-console
      console.warn(error.message)
    }
  })
}

export function useCenterMap(
  view: __esri.MapView | __esri.SceneView,
  graphics?: __esri.Graphic | __esri.Graphic[],
  immediate = false
) {
  const ready = !!view && Array.isArray(graphics)
    ? graphics?.length > 0
    : !!graphics

  const fn = useCallback((overrides?: __esri.Graphic | __esri.Graphic[]) => {
    centerMap(view, overrides || graphics)
  }, [
    view,
    graphics
  ])

  useEffect(() => {
    if(fn && ready && immediate) {
      fn()
    }
  }, [
    ready,
    immediate,
    fn
  ])

  return fn
}

export function useMapMove(
  view: ActiveView,
  onMoveStart?:(view: ActiveView, args?: any) => void,
  onMoveEnd?:(view: ActiveView, args?: any) => void
) {
  useEffect(() => {
    if(!view || (!onMoveStart && !onMoveEnd)) return
    let moveEnd: Promise<void>
    let moveStart: IHandle
    whenOnce(() => view.ready).then(() => {
      moveStart = when(() => !view.stationary, (...args) => {
        if(view.stationary) return
        if(onMoveStart) onMoveStart(view, args)
        if(!onMoveEnd) return
        moveEnd = whenOnce(() => view.stationary).then((...args) => {
          if(!view?.viewpoint) return
          onMoveEnd(view, args)
        })
      })
    })
     return () => {
      if(moveStart) moveStart.remove()
      if(moveEnd) Promise.resolve(moveEnd)
    }
  }, [
    view,
    onMoveStart,
    onMoveEnd
  ])
}

type UseMapZoomInput = {
  view: ActiveView,
  onZoomStart?:(view: ActiveView, args?: any) => void,
  onZoomEnd?:(view: ActiveView, args?: any) => void

}
export function useMapZoom(input: UseMapZoomInput) {
  const { view, onZoomStart, onZoomEnd } = input
  useEffect(() => {
    if(!view || (!onZoomStart && !onZoomEnd)) return
    let zoomEnd: Promise<void>
    let zoomStart: IHandle
    view.when(() => {
      zoomStart = view.watch("zoom", () => {
        if(onZoomStart) onZoomStart(view)
        if(!onZoomEnd) return
        zoomEnd = whenOnce(() => view.stationary).then(() => {
          if(!view?.viewpoint) return
          onZoomEnd(view)
        })
      })
    })
    return () => {
      if(zoomStart) zoomStart.remove()
      if(zoomEnd) Promise.resolve(zoomEnd)
    }
  }, [
    view,
    onZoomStart,
    onZoomEnd
  ])
}

export function removeDatabaseLayersFromView(view: ActiveView) {
  for(const layer of view.map.layers) {
    const { Id } = layer as Layer
    if(Id) view.map.layers.remove(layer)
  }
}

type ClearStickyHighlightHandlers = {
  leaveHandler: IHandle,
  enterHandler: IHandle
}

type HighlightOptions = ActiveView["highlightOptions"]
type PrevHighlightOptions = {
  "2d": HighlightOptions,
  "3d": HighlightOptions
}

const prevHighlightOptions: PrevHighlightOptions = {
  "2d": null,
  "3d": null
}

const HIGHLIGHT_DISABLED_OPTIONS: HighlightOptions = {
  haloOpacity: 0,
  fillOpacity: 0
}

export function clearStickyHighlight(view: ActiveView) {
  const out: ClearStickyHighlightHandlers = {
    leaveHandler: null,
    enterHandler: null
  }
  out.leaveHandler = view.on("pointer-leave", () => {
    prevHighlightOptions[view.type] = view.highlightOptions
    view.highlightOptions = HIGHLIGHT_DISABLED_OPTIONS
  })
  out.enterHandler = view.on("pointer-enter", () => {
    if(prevHighlightOptions[view.type]) {
      view.highlightOptions = prevHighlightOptions[view.type]
    }
  })
  return out
}

export function useClearStickyHighlights(view: ActiveView) {
  useEffect(() => {
    if(!view) return
    const { leaveHandler, enterHandler } = clearStickyHighlight(view)
    return () => {
      if(prevHighlightOptions[view.type]) {
        view.highlightOptions = prevHighlightOptions[view.type]
      }
      leaveHandler.remove()
      enterHandler.remove()
    }
  }, [
    view
  ])
}

type XY = {
  x: number,
  y: number
}

export function getIsPointWithinMapViewConstraints(view: ActiveView, xy?: XY) {
  if(!xy || view.type === "3d") return true
  const ext = view.constraints.geometry as __esri.Extent
  const { ymin, ymax, xmin, xmax } = ext
  const { x, y } = xy
  return y > ymin && y < ymax && x > xmin && x < xmax
}

export function useIsViewReady(view: ActiveView) {
  const [ isReady, setIsReady ] = useState(false)

  useEffect(() => {
    if(!view) return

    const handle = when(() => {
      return view.ready && !view.updating
    }, () => {
      setIsReady(true)
    }, {
      once: true
    })

    return () => {
      handle.remove()
    }
  }, [
    view
  ])

  return isReady
}

export function usePreventShiftZoom(view: ActiveView) {
  useEffect(() => {
    if(!view) return
    const h = view.on("drag", [ "Shift" ], (e) => {
      e.stopPropagation()
    })
    return () => {
      h.remove()
    }
  }, [
    view
  ])
}

export function findWidget<T extends __esri.Widget>(
  id: string,
  view: __esri.View
) {
  return view.ui.find(id) as T
}

export function normalizeSceneViewCameraHeading(heading: number) {
  return (360 - heading) % 360
}

export function useActiveViewRotation(view: ActiveView) {
  const [ rotation, setRotation ] = useState(0)

  const isReady = useIsViewReady(view)

  useEffect(() => {
    if(!isReady || !assertMapView(view)) return
    const handle = view.watch("rotation", setRotation)
    return () => handle.remove()
  }, [ view, isReady ])

  useEffect(() => {
    if(!isReady || !assertSceneView(view)) return
    const handle = view.watch("camera", () => {
      // Normalize SceneView's heading to match MapView's rotation
      const adjustedHeading = normalizeSceneViewCameraHeading(view.camera.heading)
      setRotation(adjustedHeading)
    })
    return () => handle.remove()
  }, [ view, isReady ])

  return {
    rotation,
    setRotation: (r: number) => {
      if(assertMapView(view)) {
        view.goTo({
          rotation: r
        }, {
          duration: 200,
          easing: "ease-out"
        })
      }
      if(assertSceneView(view)) {
        view.goTo({
          heading: r
        }, {
          duration: 200
        })
      }
    }
  }
}

export function applyRotationFromPreviousView(previousView: ActiveView, view: ActiveView) {
  if(previousView && assertSceneView(previousView) && previousView.camera) {
    const heading = previousView.camera.heading
    const normalizedHeading = normalizeSceneViewCameraHeading(heading)
    if(assertMapView(view)) {
      view.rotation = normalizedHeading
    }
    if(assertSceneView(view)) {
      view.camera.heading = normalizedHeading
    }
  }
}

export const WEBGL2_CREATE_ERROR = "Unable to create WebGLContext"
export const WEBGL2_LOSE_ERROR = "WebGL2 context could not be lost"
export const WEBGL2_USER_ERROR = "Det skjedde en feil da applikasjonen forsøkte å laste kartet. Dersom problemet vedvarer kan det hende at applikasjonen ikke støtter nettleseren din. Om dette er tilfellet anbefaler vi at du prøver en annen nettleser."

export function getWebglContextFromView(view: ActiveView) {
  let error: Error = null
  let context: WebGL2RenderingContext
  const canvas: HTMLCanvasElement = view.container.querySelector("canvas")
  if(canvas) {
    context = canvas.getContext("webgl2")
    if(!context) {
      error = new Error(WEBGL2_CREATE_ERROR)
    }
  }
  return { context, error }
}

export function loseWebGl2Context(ctx: WebGL2RenderingContext) {
  const ext = ctx.getExtension("WEBGL_lose_context")
  if(!ext) {
    return new Error(WEBGL2_LOSE_ERROR)
  }
  ext.loseContext()
  const isLost = ctx.isContextLost()
  if(!isLost) {
    return new Error(WEBGL2_LOSE_ERROR)
  }
}
