import { useEffect } from "react"
import Point from "@arcgis/core/geometry/Point"
import Viewpoint from "@arcgis/core/Viewpoint"
import { safeJSONParse } from "@/v2-common/utils/utils.misc"
import { DEFAULT_VIEWPOINT } from "@/v2-map-ui/map.constants"
import type { ActiveView } from "@/v2-map-ui/map.types"

export type UrlViewpoint = {
  tx: number, // target position x
  ty: number, // target position y
  tz: number, // target position z
  cx: number, // camera position x
  cy: number, // camera position y
  cz: number, // camera position z
  ct: number, // camera tilt
  ch: number, // camera heading
  cf: number, // camera fov
  r: number, // rotation
  s: number, // scale,
  wkid: number // spatial reference,
  cc: number // country code
}

const viewpointUrlKeys = [
  "tx", "ty", "tz", "cx", "cy", "cz",
  "ct", "ch", "cf", "r", "s", "wkid", "cc"
] as const

const fallbackLocation = {
  x: 261993,
  y: 6649383,
  z: 14
}

/**
 * Parses URL parameters into a viewpoint configuration.
 *
 * @param params - The viewpoint parameters extracted from the URL.
 * @param useFallbacks - Whether to use fallback values for missing parameters. Defaults to `false`.
 *
 * @note: The fallback locations are only valid for norway  most of this usecase has been moved to the basemap viewpoin/constraints

 * @returns A viewpoint object or `null` if parameters are insufficient.
 */
export function getViewpointFromUrlParams(
  params: UrlViewpoint,
  useFallbacks = false
) {
  let wp: Partial<UrlViewpoint> = null

  for(const key of viewpointUrlKeys) {
    const p = params[key]
    if(p) {
      if(!wp) wp = {}
      wp[key] = p
    } else if(useFallbacks) {
      if(!wp) wp = {}
      wp[key] = DEFAULT_VIEWPOINT[key]
    }
  }

  if(!wp) return null

  const { cx, cy, cz, ct, ch, cf, r, s, wkid } = { ...wp }
  let { tx, ty, tz } = { ...wp }

  if(!tx || !ty) {
    tx = fallbackLocation.x
    ty = fallbackLocation.y
    tz = fallbackLocation.z
  }

  const props: __esri.ViewpointProperties = {
    targetGeometry: new Point({
      x: tx,
      y: ty,
      z: tz,
      spatialReference: {
        wkid: wkid
      }
    }),
    rotation: r || 0,
    scale: s ?? 1000,
    camera: {
      tilt: ct ?? 65,
      heading: ch ?? 0,
      fov: cf ?? 55,
      position: {
        x: cx || tx,
        y: cy || ty,
        z: cz || tz,
        spatialReference: {
          wkid: wkid
        }
      }
    }
  }

  return new Viewpoint(props)
}

/**
 * Serializes a viewpoint object into a JSON string.
 *
 * @param props - The viewpoint properties or object to serialize.
 * @returns A JSON string representation of the viewpoint.
 */
export function serializeViewpoint(
  props: __esri.ViewpointProperties | __esri.Viewpoint
) {
  return JSON.stringify(props)
}

/**
 * Deserializes a JSON string into a viewpoint object.
 *
 * @param input - The JSON string to deserialize.
 * @returns A viewpoint object or `null` if deserialization fails.
 */
export function deSerializeViewpoint(input: string) {
  const props = safeJSONParse<__esri.ViewpointProperties>(input)
  if(!props) return null
  props.targetGeometry = new Point(props.targetGeometry)
  return new Viewpoint(props)
}

/**
 * Saves a viewpoint to local storage, associated with a country code.
 *
 * @param countryCode - The country code to associate with the saved viewpoint.
 * @param wp - The viewpoint object to save.
 */
function saveViewpointToStorage(
  countryCode: number,
  wp: __esri.ViewpointProperties | __esri.Viewpoint
) {
  window.localStorage[`lastViewpoint-${countryCode}`] = serializeViewpoint(wp)
}

/**
 * Retrieves a saved viewpoint from local storage using a country code.
 *
 * @param countryCode - The country code associated with the viewpoint.
 * @returns The deserialized viewpoint object or `null` if not found.
 */
export function getViewpointFromStorage(countryCode: number) {
  const lastViewpoint = window.localStorage[`lastViewpoint-${countryCode}`]
  if(!lastViewpoint) return null
  return deSerializeViewpoint(lastViewpoint)
}

/**
 * Parses a hash string from the URL into viewpoint parameters.
 *
 * @param hash - The URL hash string.
 * @returns The parsed viewpoint parameters as an object.
 */
function deSerializeHashParams(hash: string) {
  const out = {} as UrlViewpoint
  for(const p of hash.slice(1).split("&")) {
    const s = p.split("=")
    out[s[0]] = s[1]
  }
  return out
}

/**
 * Retrieves a viewpoint from the current URL hash.
 *
 * @returns A viewpoint object derived from URL parameters or `null` if invalid.
 */
export function getViewpointFromUrl() {
  const params = deSerializeHashParams(window.location.hash)
  return getViewpointFromUrlParams(params)
}

/**
 * Retrieves a viewpoint from the current URL hash.
 *
 * @returns A viewpoint object derived from URL parameters or `null` if invalid.
 */
export function getViewpointFromUrlOrStorage(countryCode: number) {
  const wp = getViewpointFromUrl()
  if(wp) return wp
  return getViewpointFromStorage(countryCode) || undefined
}

/**
 * A React hook that saves the current viewpoint to local storage before page unload.
 *
 * @note: We cannot save viewpoint on demount as is, without making sure rerenders are not triggered etc

 *
 * @param countryCode - The country code to associate with the saved viewpoint.
 */
export function useViewpointSaver(countryCode: number) {
  useEffect(() => {
    const onBeforeUnload = () => {
      const wp = getViewpointFromUrl()
      saveViewpointToStorage(countryCode, wp)
    }
    window.addEventListener("beforeunload", onBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload)
    }
  }, [ countryCode ])
}

/**
 * Extracts viewpoint properties from a viewpoint entity.
 *
 * @param viewpoint - The viewpoint object to extract properties from.
 * @returns A partial `UrlViewpoint` object.
 */
export function extractViewpointProperties(
  viewpoint: __esri.Viewpoint,
  countryCode?: number
) {
  const {
    targetGeometry,
    camera,
    rotation: r,
    scale: s
  } = viewpoint

  const { x: tx, y: ty, z: tz, spatialReference: { wkid } } = targetGeometry as __esri.Point

  let urlViewpoint: Partial<UrlViewpoint> = {
    tx, ty, tz, r, s, wkid, cc: countryCode
  }

  if(camera) {
    const { position, heading: ch, tilt: ct, fov: cf } = camera
    const { x: cx, y: cy, z: cz } = position

    urlViewpoint = {
      ...urlViewpoint,
      cx, cy, cz, ch, ct, cf
    }
  }

  return urlViewpoint
}

/**
 * Converts a `UrlViewpoint` object into a URL hash string.
 *
 * @param urlViewpoint - The viewpoint properties to convert.
 * @returns A hash string representation of the viewpoint.
 */
function createHashParamsFromUrlViewpoint(
  urlViewpoint: Partial<UrlViewpoint>
) {
  let str = ""
  for(const [ key, value ] of Object.entries(urlViewpoint)) {
    if(key && value) {
      str += `${key}=${Math.round(value)}&`
    }
  }
  return str.slice(0, -1)
}

/**
 * Updates the URL with the current viewpoint from a given view.
 *
 * @param view - The active view to retrieve the viewpoint from.
 */
export function updateUrlWithViewpointFromView(view?: ActiveView, countryCode?: number) {
  if(!view?.viewpoint) return
  updateUrlWithViewpoint(view.viewpoint, countryCode)
}

/**
 * Updates the URL with the given viewpoint object.
 *
 * @param viewpoint - The viewpoint to use for the URL update.
 */
export function updateUrlWithViewpoint(viewpoint: __esri.Viewpoint, countryCode?: number) {
  const wp = extractViewpointProperties(viewpoint, countryCode)
  const hash = createHashParamsFromUrlViewpoint(wp)
  const { pathname, search } = window.location
  let url = pathname
  if(search) url = `${url}${search}`
  if(hash) url = `${url}#${hash}`
  window.history.replaceState(null, null, url)
}

/**
 * Handles URL updates when switching between country codes.
 *
 * @param currentCountryCode - The current country code.
 * @param newCountryCode - The new country code to switch to.
 */
export function updateUrlWithViewpointOnCountrySwitch(
  currentCountryCode: number,
  newCountryCode: number
) {
  // save params from current country
  const currentWp = getViewpointFromUrl()
  saveViewpointToStorage(currentCountryCode, currentWp)

  // try to replace the url with coordinates from the new country
  const newWp = getViewpointFromStorage(newCountryCode)
  // if no viewpoint is saved for the new country, remove url params all together
  if(!newWp) {
    window.history.replaceState(null, "", window.location.pathname + window.location.search)
    return
  }
  updateUrlWithViewpoint(newWp, newCountryCode)
}

/**
 * Retrieves a specific parameter from the URL hash.
 *
 * @param key - The key of the parameter to retrieve.
 * @returns The value of the parameter or `null` if not found.
 */
function getHashParam(key: string) {
  const hashParams = new URLSearchParams(window.location.hash.slice(1))
  return hashParams.get(key)
}

/**
 * Retrieves the country code from the URL hash.
 *
 * @returns The value of the parameter or `null` if not found.
 */
export function getCountryCodeFromHashParam() {
  return +getHashParam("cc")
}
