import commonConfig from "@/v2-common/config"

export const DEFAULT_SERVER_ERROR_MESSAGE
  = "A problem occurred on the server"

export function assertGenericError(e: Error): e is GenericError {
  return e instanceof GenericError
}

export function formatErrorHttpResponse(error: Error) {
  const statusCode = error instanceof GenericError
    ? error.statusCode
    : 500

  const errorMessage = statusCode >= 500 && commonConfig.isProd
    ? DEFAULT_SERVER_ERROR_MESSAGE
    : error.message

  return {
    statusCode,
    errorMessage
  }
}

export type ErrorDetails = Record<string, any> // Obj & CaptureContext
export type ErrorOptions = ErrorDetails & {
  cause?: unknown,
  isLogged?: boolean,
  isReported?: boolean,
  statusCode?: number
}

/**
 * An error with extra details to be used for logging
 *
 * @todo forward details to sentry etc..
 */
export class GenericError extends Error {
  /** The statusCode a http server should respond with */
  statusCode = 500
  /** The error was logged to stdout (pino) */
  isLogged = false
  /** The error were reported to external debug tools (sentry) */
  isReported = false
  /** Additional debug info, can be anything */
  details: ErrorDetails = {}

  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, GenericError)
    if(options) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { cause, statusCode, isLogged, isReported, ...details } = options
      if(statusCode) this.statusCode = statusCode
      if(isLogged) this.isLogged = true
      if(isReported) this.isReported = true
      if(details) this.details = details
    }
  }
}

/**
 * Auth error is used to communicate that an error
 * occurred with access permissions
 */
export class AuthError extends GenericError {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, AuthError)
    this.statusCode = options?.statusCode ?? 401
    // AuthError should probably never be logged or reported, but
    // by setting these defaults we can allow for exceptions
    this.isLogged = options?.isLogged ?? true
    this.isReported = options?.isReported ?? true
  }
}

/**
 * Input error is used to communicate that an error
 * occurred with the request, either due to invalid
 * validation, or malformed data
 */
export class InputError extends GenericError {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, InputError)
    this.statusCode = options?.statusCode ?? 400
  }
}

/**
 * Used when a request tried to hit a route that
 * did not exist
 */
export class NotFoundError extends GenericError {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, NotFoundError)
    this.statusCode = options?.statusCode ?? 404
  }
}

/**
 * Error class to handle metadata configuration issues, such as
 * when ArcGIS layer configuration in the database does not match
 * the expected structure or values
 */
export class ConfigError extends GenericError {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, ConfigError)
    this.statusCode = options?.statusCode ?? 500
  }
}

/**
 * Used when there is a problem with a third party service,
 * that is usually located outside system boundaries
 *
 * @todo forward extras to sentry etc..
 */
export class ExternalServiceError extends GenericError {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, ExternalServiceError)
    this.statusCode = options?.statusCode ?? 502
  }
}

/**
 * This badboiii be throwin' when dey be spammin'
 *
 * @todo forward extras to sentry etc..
 */
export class RateLimitExceededError extends GenericError {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, RateLimitExceededError)
    this.statusCode = options?.statusCode ?? 429
  }
}

/**
 * The user did not fullfil the legal requirements in order to
 * be compliant
 *
 * @example A user has not yet confirmed to conform to the GDPR
 * compliance requirements
 *
 * @todo forward extras to sentry etc..
 */
export class ComplianceError extends GenericError {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    Error.captureStackTrace(this, ComplianceError)
    this.statusCode = options?.statusCode ?? 451
  }
}
