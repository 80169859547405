import type { TypePolicies } from "@apollo/client"
import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client"
import { getTagById }
  from "@/v2-console/tag/tag.byId.typePolicies"
import { getManyObjectTagsAggregated }
  from "@/v2-console/tag/aggregated/tag.aggregated.typePolicies"
import { getManyTagsByObject }
  from "@/v2-console/tag/object/tag.object.typePolicies"

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      getTagById,
      getManyObjectTagsAggregated,
      getManyTagsByObject
    }
  },
  Mutation: {
    fields: {
    }
  }
}

type DataObj = {
  Id?: string,
  Code?: string,
  __typename: string
}

const dataIdFromObject = (obj: DataObj) => {
  const id = obj.Id || obj.Code
  // This to fix a cache conflict between tag and a active tag.
  // A active tag is a tag that is associated with a object and therefor has a ObjectTagId.
  if(obj.__typename === "Tag" && id && (obj as any).ObjectTagId) {
    return `${obj.__typename}:${id}:${(obj as any).ObjectTagId}`
  }
  if(obj.__typename === "UserKeyValue" && (obj as any).Key) {
    return `${obj.__typename}:${(obj as any).Key}`
  }
  return id
    ? `${obj.__typename}:${id}`
    : defaultDataIdFromObject(obj)
}

const cache = new InMemoryCache({
  typePolicies,
  dataIdFromObject
})

export default cache
