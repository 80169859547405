import FeatureLoadingFallback from "@/v2-console/feature/FeatureLoadingFallback"
import Card from "@/v2-ui/card"

/*
 * Loading fallback that mimics the card look of the cadastre moduless
*/
function CadastreModuleLoadingFallback() {
  return (
    <Card>
      <Card.Header>
        <Card.Title>{""}</Card.Title>
      </Card.Header>
      <Card.Content className="h-72">
        <FeatureLoadingFallback />
      </Card.Content>
    </Card>
  )
}

export default CadastreModuleLoadingFallback
