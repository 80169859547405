import { useCallback, useEffect, useState } from "react"
import dot, { pick } from "dot-object"
import type { Feature as GeojsonFeature, Polygon as GeoJsonPolygon } from "geojson"
import Graphic from "@arcgis/core/Graphic"
import Polygon from "@arcgis/core/geometry/Polygon"
import Query from "@arcgis/core/rest/support/Query"
import Collection from "@arcgis/core/core/Collection"
import { ExternalServiceError, ConfigError } from "@/v2-common/errors"
import { useToast } from "@/v2-ui/toast/base/hooks/use-toast"
import type { ActiveView } from "@/v2-map-ui/map.types"
import { fetchLayerFeatures }
  from "@/v2-map-ui/layer/utils/map.layer.utils"
import { createLayer }
  from "@/v2-map-ui/layer/utils/map.layer.utils"
import type { NavLayerFragment }
  from "@/v2-console-shared/navLayer/__types__/navLayer.fragment"
import type { NavLayerParametersFeatureLayerFragment }
  from "@/v2-console-shared/navLayer/__types__/navLayer.parameters.featureLayer.fragment"
import type { NavLayerParametersOgcFeatureLayerFragment }
  from "@/v2-console-shared/navLayer/__types__/navLayer.parameters.ogcFeatureLayer.fragment"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"
import { SYMBOL }
  from "@/v2-console/map/layer/navLayer/map.layer.navLayer.constants"
import {
  useNavLayerSettings,
  updateNavLayerSettings,
  useNavLayerTempDisabled
} from "@/v2-console/map/layer/navLayer/map.layer.navLayer.var"
import {
  DEFAULT_NAV_LAYER_CONFIG,
  NAV_LAYER_DISABLED_ID,
  NAV_LAYER_DEFAULT_MINSCALE
} from "@/v2-console/map/layer/navLayer/map.layer.navLayer.constants"

function assertNavLayerParameterFeatureLayer(
  fragment: NavLayerFragment["Configuration"]["parameters"]
): fragment is NavLayerParametersFeatureLayerFragment {
  return fragment?.__typename === "NavLayerParametersFeatureLayer"
}

function assertNavLayerParameterOGCFeatureLayer(
  fragment: NavLayerFragment["Configuration"]["parameters"]
): fragment is NavLayerParametersOgcFeatureLayerFragment {
  return fragment?.__typename === "NavLayerParametersOGCFeatureLayer"
}

/**
 * Retrieves the default active navigation layer from the provided list of navigation layers.
 *
 * This function finds the default navigation layer in the given list by checking
 * the `isDefault` property in the layer's `Configuration` field. If no default
 * layer is found, it returns the first layer in the list. If the list is empty
 * or not provided, it returns `null`.
 *
 * @param navLayers - The list of navigation layers to search through.
 * @returns The default active navigation layer, the first layer
 * in the list, or `null` if no layers are provided.
 */
function getDefaultActiveNavLayer(
  navLayers: NavLayerFragment[]
) {
  if(!navLayers || navLayers?.length === 0) return null
  return navLayers.find((l) => l.Configuration.isDefault) || navLayers.at(0)
}

/**
 * Retrieves the active navigation layer based on the provided navigation layers and selected option.
 *
 * @param navLayers - The fragment containing available navigation layers.
 * @param option - The selected navigation layer option to retrieve.
 *
 * @returns The active navigation layer if it exists, otherwise `null`.
 */
function getActiveNavLayer(
  navLayers: NavLayerFragment[],
  option: string
) {
  if(!navLayers) return null
  return navLayers.find((l) => l.Code === option)
}

type HandleUnsupportedNavLayerInput = {
  navLayers: NavLayerFragment[],
  countryCode: number
  toast: ReturnType<typeof useToast>["toast"],
}

/**
 * Handles unsupported navigation layers for a given country.
 * If no navigation layers are found, it displays a toast notification.
 * Otherwise, it sets the default navigation layer.
 *
 * @param props - The input properties for handling unsupported navigation layers.
 * @param props.navLayers - An array of navigation layer fragments.
 * @param props.countryCode - The country code for identifying the country.
 * @param props.toast - A toast function for displaying notifications.
 */
function handleUnsupportedNavLayer(props: HandleUnsupportedNavLayerInput) {
  const { navLayers, countryCode, toast } = props
  if(navLayers?.length === 0) {
    toast({
      variant: "destructive",
      duration: Number.POSITIVE_INFINITY,
      title: "Navigation layer is not supported",
      description: (
        <>
          We could not find any navigation layers for the current country.
        </>
      )
    })
  } else {
    // update the navLayer to a supported one if nothing is spesified
    const defaultNavLayer = getDefaultActiveNavLayer(navLayers)
    const newNavLayerSettings = {
      activeId: defaultNavLayer.Code
    }
    updateNavLayerSettings(newNavLayerSettings, countryCode)
  }
}

/**
 * Hook to retrieve the currently active navigation layer and handle nav layer switching.
 * If the active layer is unsupported, displays a toast notification.
 *
 * @returns The active navigation layer if available.
 */
export function useActiveNavLayer() {
  const { navLayers, currentUser } = useAppContext()
  const { navLayerSettings, isNavLayerSettingsLoading } = useNavLayerSettings()
  const isNavLayerTempDisabled = useNavLayerTempDisabled()
  const { toast } = useToast()

  const [ activeNavLayer, setActiveNavLayer ] = useState<NavLayerFragment>()

  // update settings with the default layer if no settings are found
  useEffect(() => {
    if(!isNavLayerSettingsLoading && navLayerSettings) return
    if(!navLayers || !currentUser?.ActiveCountryCode) return

    const defaultNavLayer = getDefaultActiveNavLayer(navLayers)
    const newNavLayerSettings = {
      minScale: NAV_LAYER_DEFAULT_MINSCALE,
      activeId: defaultNavLayer.Code
    }
    updateNavLayerSettings(newNavLayerSettings, currentUser.ActiveCountryCode)
  }, [
    isNavLayerSettingsLoading,
    navLayerSettings,
    navLayers,
    currentUser?.ActiveCountryCode
  ])

  // update the active nav layer when the settings are loaded
  useEffect(() => {
    if(!currentUser?.ActiveCountryCode || !navLayerSettings || !navLayers) return

    // if the nav layer is temporarily disabled, set the active layer to null
    if(isNavLayerTempDisabled){
      setActiveNavLayer(null)
    }

    // return early if the activeLayer is the same as the activeId
    if(activeNavLayer?.Code === navLayerSettings.activeId) return

    // get the active layer
    const active = getActiveNavLayer(navLayers, navLayerSettings.activeId)

    // handle invalid active navlayer settings
    if(!active && navLayerSettings.activeId !== NAV_LAYER_DISABLED_ID) {
      handleUnsupportedNavLayer({
        navLayers,
        countryCode: currentUser.ActiveCountryCode,
        toast
      })
      return
    }

    setActiveNavLayer(active)
  }, [
    toast,
    currentUser?.ActiveCountryCode,
    activeNavLayer,
    navLayerSettings,
    isNavLayerTempDisabled,
    navLayers
  ])

  // @note: this should create a re-render if the navLayerSettings are updated
  // in order to properly re-fetch the features accordingly
  const createActiveNavLayer = useCallback(() => {
    if(!navLayerSettings) return null
    // override the minScale if possible
    const configWithOverrides = {
      ...DEFAULT_NAV_LAYER_CONFIG,
      minScale: navLayerSettings.minScale
    }
    const layer = createLayer<__esri.GraphicsLayer>(configWithOverrides, "graphics")
    return layer

  }, [ navLayerSettings ])

  return {
    activeNavLayer,
    createActiveNavLayer: navLayerSettings && activeNavLayer ? createActiveNavLayer : null
  }
}

/**
 * Fetches the navigation layer features based on the view and navigation layer query.
 *
 * @param view - The active view containing the current extent and spatial reference.
 * @param navLayer - The navigation layer query fragment with the properties of the layer to be fetched.
 * @param attributePathKey - The key used to access the attribute value in the graphic's attributes used for finding the graphic with the lowest attribute value.
 *
 * @throws {ConfigError} If the query cannot be asserted to a supported navigation layer type.
 *
 * @returns A promise that resolves with the parsed navigation layer features.
 */
export async function fetchNavLayerFeatures(
  view: ActiveView,
  navLayer: NavLayerFragment,
  attributePathKey?: string
) {
  if(assertNavLayerParameterFeatureLayer(navLayer.Configuration.parameters)) {
    const res = await fetchNavLayerFeaturesFromFeatureLayer(view, navLayer)
    return parseNavLayerFeaturesFromFeatureLayer(res.features, attributePathKey)
  }
  if(assertNavLayerParameterOGCFeatureLayer(navLayer.Configuration.parameters)) {
    const res = await fetchNavLayerFeaturesFromOGCFeatureLayer(view, navLayer)
    // @note: OCGFeatureLayer returns geojson
    return parseNavLayerFeaturesFromOGCFeatureLayer(res.features, view.spatialReference)
  }

  throw new ConfigError(
    "Invalid nav layer query. Could not assert query to a supported type..",
    navLayer
  )
}

export async function fetchNavLayerFeaturesFromPoint(
  point: __esri.Point,
  navLayer: NavLayerFragment
) {
  if(assertNavLayerParameterFeatureLayer(navLayer.Configuration.parameters)) {
    const res = await fetchNavLayerFeaturesFromFeatureLayerUsingPoint(point, navLayer)
    return parseNavLayerFeaturesFromFeatureLayer(res.features)
  }
  if(assertNavLayerParameterOGCFeatureLayer(navLayer.Configuration.parameters)) {
    const res = await fetchNavLayerOGCFeaturesFromPoint(point, navLayer)
    // @note: OCGFeatureLayer returns geojson
    return parseNavLayerFeaturesFromOGCFeatureLayer(res.features, point.spatialReference)
  }
}

function fetchNavLayerFeaturesFromFeatureLayerUsingPoint(
  point: __esri.Point,
  navLayer: NavLayerFragment
) {
  if(!assertNavLayerParameterFeatureLayer(navLayer.Configuration.parameters)) {
    throw new ConfigError(
      "Invalid nav layer query. Expected feature layer parameters...",
      navLayer
    )
  }

  const query: __esri.QueryProperties = {
    geometry: point,
    ...navLayer.Configuration.parameters as __esri.QueryProperties
  }
  return fetchLayerFeatures(navLayer.Configuration.url, new Query(query))
}
/**
 * Fetches navigation layer feature from a feature layer based on the given query and map view extent.
 *
 * @param view - The active map view with the current extent of the map.
 * @param navLayer - The navigation layer query fragment containing properties like geometry and layer URL.
 *
 * @throws {ConfigError} If the navigation layer query parameters are invalid.
 *
 * @returns A promise that resolves with the fetched features in Graphicfrom the feature layer.
 */
function fetchNavLayerFeaturesFromFeatureLayer(
  view: ActiveView,
  navLayer: NavLayerFragment
) {
  const c = navLayer.Configuration
  if(!assertNavLayerParameterFeatureLayer(c.parameters)) {
    throw new ConfigError(
      "Invalid nav layer query. Expected feature layer parameters...",
      navLayer
    )
  }

  const query: __esri.QueryProperties = {
    geometry: view.extent,
    ...c.parameters as __esri.QueryProperties
  }
  return fetchLayerFeatures(c.url, new Query(query))
}

/**
 * Parses an array of features from a feature layer, assigns a predefined symbol to each feature,
 * and returns them as a new collection.
 *
 * @param features - An array of graphics from the feature layer to be parsed and symbolized.
 * @param attributePathKey - The key used to access the attribute value in the graphic's attributes used for finding the graphic with the lowest attribute value.
 *
 * @returns A new collection of graphics with the applied symbol.
 */
function parseNavLayerFeaturesFromFeatureLayer(features: __esri.Graphic[], attributePathKey?: string) {
  if(attributePathKey) {
    return getLowestAttributeGraphicsByPath(features, attributePathKey)
  }
  return new Collection(features.map((g) => {
    g.symbol = SYMBOL
    return g
  }))
}

/**
 * Retrieves the graphics with the lowest attribute value for each unique geometry.
 *
 * This function iterates through a list of graphics and selects the graphic with the lowest
 * attribute value for each unique geometry. The uniqueness of a geometry is determined by
 * its JSON string representation. If a graphic with the same geometry already exists in the
 * map, the function compares the attribute values and retains the one with the lower value.
 * The selected graphics are assigned a specific symbol.
 *
 * @param {__esri.Graphic[]} features - An array of graphics to be processed.
 * @param {string} attributePathKey - The key used to access the attribute value in the graphic's attributes.
 * @returns {Map<string, __esri.Graphic>} A map of unique graphics with the lowest attribute values.
 */
function getLowestAttributeGraphicsByPath(features: __esri.Graphic[], attributePathKey: string) {
  const uniqueGraphics = new Map<string, __esri.Graphic>()

  for(const g of features) {
    const uniqueKey = JSON.stringify({ geometry: g.geometry })
    const currentAttributeValue = pick(attributePathKey, g.attributes) ?? Number.POSITIVE_INFINITY

    if(uniqueGraphics.has(uniqueKey)) {
      // Replace if the new graphic has a lower attribute value
      const existingGraphic = uniqueGraphics.get(uniqueKey)
      const existingAttributeValue = pick(attributePathKey, existingGraphic.attributes) ?? Number.POSITIVE_INFINITY

      if(currentAttributeValue < existingAttributeValue) {
        g.symbol = SYMBOL
        uniqueGraphics.set(uniqueKey, g)
      }
    } else {
      // Add the graphic if it doesn't exist in the map
      g.symbol = SYMBOL
      uniqueGraphics.set(uniqueKey, g)
    }
  }

  // Return the Collection directly from the map values
  return new Collection([ ...uniqueGraphics.values() ])
}

/**
 * Fetches navigation layer feature from an OCG feature layer based on the given query and map view extent.
 *
 * @param view - The active map view with the current extent of the map.
 * @param navLayer - The navigation layer query fragment containing parameters like API key and CRS.
 *
 * @throws {ConfigError} If the navigation layer query parameters are invalid.
 * @throws {ExternalServiceError} If the external service returns an error or if the fetch operation fails.
 *
 * @returns A promise that resolves with the fetched feature data in GEOJSON format, or an empty object if the response is empty.
 */
async function fetchNavLayerFeaturesFromOGCFeatureLayer(
  view: ActiveView,
  navLayer: NavLayerFragment
) {
  if(!assertNavLayerParameterOGCFeatureLayer(navLayer.Configuration.parameters)) {
    throw new ConfigError(
      "Invalid nav layer query. Expected OCG feature layer parameters...",
      navLayer
    )
  }

  const { xmin, ymin, xmax, ymax } = view.extent

  const res = await fetchNavLayerBoundingBoxOGCFeatures(
    xmin,
    ymin,
    xmax,
    ymax,
    view.spatialReference,
    navLayer
  )

  return res
}

export async function fetchNavLayerOGCFeaturesFromPoint(
  point: __esri.Point, // Point input prop to be used as a bounding box
  navLayer: NavLayerFragment
) {
  // Create a small bounding box around the point with a buffer distance
  const bufferDistance = 10 // in meters
  const xmin = point.x - bufferDistance
  const ymin = point.y - bufferDistance
  const xmax = point.x + bufferDistance
  const ymax = point.y + bufferDistance

  const res = await fetchNavLayerBoundingBoxOGCFeatures(
    xmin,
    ymin,
    xmax,
    ymax,
    point.spatialReference,
    navLayer
  )

  return res
}

/**
 * Fetches navigation layer features from an OGC feature layer based on the provided bounding box and spatial reference.
 * The function constructs a URL with query parameters and fetches the features from the external service.
 * If the fetch operation fails, an error is thrown with details about the failure.
 * If the response is not successful, an error is thrown with the response status and details.
 * If the response is successful, the features are returned as a GeoJSON object.
 *
 * @param xmin - The minimum x-coordinate of the bounding box.
 * @param ymin - The minimum y-coordinate of the bounding box.
 * @param xmax - The maximum x-coordinate of the bounding box.
 * @param ymax - The maximum y-coordinate of the bounding box.
 * @param spatialReference - The spatial reference of the bounding box.
 * @param navLayer - The navigation layer query fragment containing parameters like URL and API key.
 *
 * @throws {ConfigError} If the navigation layer query parameters are invalid.
 * @throws {ExternalServiceError} If the external service returns an error or if the fetch operation fails.
 *
 * @returns A promise that resolves with the fetched feature data in GEOJSON format, or an empty object if the response is empty.
 */
async function fetchNavLayerBoundingBoxOGCFeatures(
  xmin: number,
  ymin: number,
  xmax: number,
  ymax: number,
  spatialReference: __esri.SpatialReference,
  navLayer: NavLayerFragment
) {
  if(!assertNavLayerParameterOGCFeatureLayer(navLayer.Configuration.parameters)) {
    throw new ConfigError(
      "Invalid nav layer query. Expected OCG feature layer parameters...",
      navLayer
    )
  }

  const { url, parameters } = navLayer.Configuration

  //@note: some vector basemap uses old wkid ids.
  // Hardgoding a map here for readability
  const outdatedWkidMap = {
    102100: 3857
  }

  const wkid = outdatedWkidMap[spatialReference.wkid] || spatialReference.wkid
  const crs = `http://www.opengis.net/def/crs/EPSG/0/${wkid}`

  const urlWithQueryParams = new URL(url)
  urlWithQueryParams.searchParams.append("bbox", `${xmin},${ymin},${xmax},${ymax}`)
  urlWithQueryParams.searchParams.append("api-key", parameters.apiKey)
  urlWithQueryParams.searchParams.append("crs", crs)
  urlWithQueryParams.searchParams.append("bbox-crs", crs)
  urlWithQueryParams.searchParams.append("f", parameters.f)
  urlWithQueryParams.searchParams.append("limit", "5000") // @note: defaults to 1_000 usually

  try {
    const res = await fetch(urlWithQueryParams)
    if(!res.ok) {
      const error = new ExternalServiceError(res.statusText)
      error.details = await res.json()
      error.details.status = res.status
      throw error
    }
    return (await res.json()) || {}
  } catch(error) {
    throw new ExternalServiceError("Fetch call failed on nav layer query", {
      cause: error,
      navLayer,
      errorMessage: error.message,
      url: urlWithQueryParams
    })
  }
}

/**
 * Parses geojson features from an OCG feature layer, creates graphics for polygon geometries,
 * and returns them as a collection of Graphics with a predefined symbol and spatial reference.
 *
 * @param features - The features to be parsed (GeoJSON format).
 * @param spatialReference - The spatial reference to apply to the geometry of the polygons.
 *
 * @returns A new collection of polygon graphics with the applied symbol and spatial reference.
 */
function parseNavLayerFeaturesFromOGCFeatureLayer(
  features: GeojsonFeature[],
  spatialReference: __esri.SpatialReference
) {
  const polygons = features.filter((g) => g.geometry.type === "Polygon")
  const collection = new Collection(polygons.map((g) => {
    const geometry = g.geometry as GeoJsonPolygon
    const graphic = new Graphic({
      symbol: SYMBOL,
      attributes: g.properties,
      geometry: new Polygon({
        rings: geometry.coordinates,
        spatialReference
      })
    })
    return graphic
  }))
  return collection
}

/**
 * Regular expression to match placeholders in the format `<key>`.
 * This is used to extract dynamic keys from the input string.
 */
const NAV_LAYER_IDENTIFIER_REGEX = /<([^>]+)>/g

/**
 * Extracts an identifier from the provided attributes based on a dynamic key pattern ( utilizing dot-object string format)
 * The function replaces placeholders in the input string with corresponding values from the attribute object.
 *
 * @param str - The string containing optional placeholders in the format `<key>`, which correspond to keys in the attribute object.
 * @param attr - An object containing attribute fields where keys correspond to the dynamic placeholders in the input string.
 * @param fallbackValue - A default value to return if the key is not found in the attribute object. Defaults to 0.
 * @returns The resulting string with placeholders replaced by attribute values, or the fallback value if a key is not found.
 *
 * @example
 * ```typescript
 * const attrFromLayerClick = {
 *   kommunenr: 1,
 *   gardsnr: 2,
 *   bruksnr: 3,
 *   festenr: 0,
 *   seksjonsnr: 0,
 * };
 * const str = "<kommunenr>/<gardsnr>/<bruksnr>/<festenr>/<seksjonsnr>"
 * const result = getNavLayerIdentifierFromAttributes(str, attrFromLayerClick);
 * console.log(result); // Output: '/map/cadastres/1/2/3/0/0'
 * ```
 */
export function getNavLayerIdentifierFromAttributes(
  str: string,
  attr: Record<string, any>,
  fallbackValue = 0
) {
  return str.replaceAll(NAV_LAYER_IDENTIFIER_REGEX, (_, key) => {
    const value = dot.pick(key, attr)
    return value ?? fallbackValue
  })
}
