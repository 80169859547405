import { useEffect, useState, useCallback, useMemo } from "react"
import { when, whenOnce } from "@arcgis/core/core/reactiveUtils"
import Collection from "@arcgis/core/core/Collection"
import MapImageLayer from "@arcgis/core/layers/MapImageLayer"
import WMSLayer from "@arcgis/core/layers/WMSLayer"
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import GroupLayer from "@arcgis/core/layers/GroupLayer"
import SceneLayer from "@arcgis/core/layers/SceneLayer"
import WFSLayer from "@arcgis/core/layers/WFSLayer"
import WMTSLayer from "@arcgis/core/layers/WMTSLayer"
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import ImageryLayer from "@arcgis/core/layers/ImageryLayer"
import OGCFeatureLayer from "@arcgis/core/layers/OGCFeatureLayer"
import { executeQueryJSON } from "@arcgis/core/rest/query/executeQueryJSON"
import type SpatialReference from "@arcgis/core/geometry/SpatialReference"
import type { Value } from "@/v2-common/types"
import { type CenterMapGraphics, centerMap } from "@/v2-map-ui/map.utils"
import type { ActiveView, Layer, LayerType }
  from "@/v2-map-ui/map.types"
import { type GroupLayerProps, assertGroupLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.groupLayer"
import { type FeatureLayerProps, assertFeatureLayer, assertFeatureLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.featureLayer"
import { assertGraphicsLayerPropsByType, type GraphicsLayer as IGraphicsLayer, type GraphicsLayerProps }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.graphicsLayer"
import { type MapImageLayerProps, assertMapImageLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.mapImageLayer"
import { type SceneLayerProps, assertSceneLayer, assertSceneLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.sceneLayer"
import { type WFSLayerProps, assertWFSLayer, assertWFSLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.wfsLayer"
import { type WMSLayerProps, assertWMSLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.wmsLayer"
import { type WMTSLayerProps, assertWMTSLayerPropsByType, assertWMTSLayer }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.wmtsLayer"
import { type ImageryLayerProps, assertImageryLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.imageryLayer"
import { type OGCFeatureLayerProps, assertOGCFeatureLayerPropsByType }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.OGCFeatureLayer"
import type { WMSSublayer }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.wmsSublayer"
import { assertCSVLayer }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.csvLayer"
import { assertGeoJSONLayer }
  from "@/v2-map-ui/layer/mixins/map.main.mixins.geoJSONLayer"
import { assertOGCFeatureLayer }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.OGCFeatureLayer"
import { assertStreamLayer }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.StreamLayer"
import {
  assertClassBreaksRenderer,
  assertColorVariable,
  assertDictionaryRenderer,
  assertDotDensityRenderer,
  assertHeatmapRenderer,
  assertMeshSymbol3D,
  assertPieChartRenderer,
  assertSimpleFillSymbol,
  assertSimpleLineSymbol,
  assertSimpleRenderer,
  assertUniqueValueRenderer,
  getColorsFrom3DMeshSymbol,
  getEdgeColorsFrom3DMeshSymbol,
  getEdgeSettingsFrom3DMeshSymbol,
  getVisualVariablesFromLayer
} from "@/v2-map-ui/graphic/map.graphic.utils"

type FetchLayerFeatureResponse = {
  displayFieldName: string
  exceededTransferLimit: boolean,
  features: __esri.Graphic[],
  fields: string[]
  geometryType: string,
  hasM: boolean,
  hasZ: boolean,
  queryGeometry: null,
  spatialReference: SpatialReference
  transform: null
}

export function fetchLayerFeatures(
  url: string,
  query: __esri.Query,
  options?: RequestInit
) {
  return executeQueryJSON(url, query, options) as Promise<FetchLayerFeatureResponse>
}

export function findLayer<T extends __esri.Layer = __esri.Layer>(
  layerId: string | number,
  view: ActiveView
) {
  return view.map.allLayers.find((layer) => {
    return layer.id === String(layerId)
  }) as T
}

export function findLayerByPredicate<T>(
  layers: __esri.Collection<__esri.Layer> | __esri.Layer[],
  predicate: (layer: __esri.Layer) => boolean
) {
  return layers.find(predicate) as T
}

// @note: this hook does not work as intended when a layer
// is added/removed in quick succession.
// e.g on cleanup and in the same hook
// then the after-remove and after-add will not trigger properly
export function useFindLayer<T extends __esri.Layer>(
  layerId: string,
  view: ActiveView,
  isWatchEnabled = true
) {
  const [ layer, setLayer ] = useState<T>(null)

  useEffect(() => {
    if(!view || !layerId) return
    view.when(() => {
      const existingLayer = findLayer<T>(layerId, view)
      if(existingLayer) {
        setLayer(existingLayer)
      }
    })
  }, [
    view,
    layerId
  ])

  useEffect(() => {
    if(!isWatchEnabled || layer || !view) return
    const h = view.map.layers.on("after-add", (e) => {
      if(e.item.id === layerId) {
        setLayer(e.item as T)
        h.remove()
      }
    })
    return () => {
      if(h) h.remove()
    }
  }, [
    layer,
    view,
    layerId,
    isWatchEnabled
  ])

  useEffect(() => {
    if(!isWatchEnabled || !layer || !view) return
    const h = view.map.layers.on("after-remove", (e) => {
      if(e.item.id === layerId) {
        setLayer(null)
        h.remove()
      }
    })
    return () => {
      if(h) h.remove()
    }
  }, [
    layer,
    view,
    layerId,
    isWatchEnabled
  ])

  return layer
}

export function getLayerIsActive(
  layers: __esri.Layer[] | __esri.Collection<__esri.Layer>,
  id: string
) {
  return layers.some((l) => l.id === id)
}

export function filterNonInternalLayers(layer: __esri.Layer) {
  return !(layer as __esri.Layer & {internal: boolean}).internal
}

export function getLayersFromViewByFilter<T extends __esri.Layer>(
  view: ActiveView,
  filter?: (layer: T) => boolean,
  includeGroupSublayers?: boolean
) {
  const layers = getLayersFromView(view, includeGroupSublayers) as T[]
  if(filter) return layers.filter(filter)
  return layers
}

export function getLayersFromGroup(groupLayer: __esri.GroupLayer) {
  const layers: __esri.Layer[] = []
  if(!groupLayer?.layers) return layers
  groupLayer.layers.map((layer) => {
    return layer.type === "group"
      ? getLayersFromGroup(layer as __esri.GroupLayer)
      : layers.push(layer)
  })
  return layers
}

export function getLayersFromView(
  view: ActiveView,
  includeGroupSublayers?: boolean
) {
  const layers: __esri.Layer[] = []
  if(!view?.map?.layers) return layers

  for(const layer of view.map.layers) {
    layers.push(layer)
    if(includeGroupSublayers && layer.type === "group") {
      for(const subLayer of getLayersFromGroup(layer as __esri.GroupLayer)) {
        layers.push(subLayer)
      }
    }
  }

  return layers
}

export function useActiveLayers<T extends __esri.Layer>(
  view: ActiveView,
  filter?: (layer: T) => boolean,
  includeGroupSublayers?: boolean
) {
  const [ activeLayers, setActiveLayers ] = useState<T[]>([])

  useEffect(() => {
    if(!view) return
    function set() {
      setActiveLayers(getLayersFromViewByFilter<T>(view, filter, includeGroupSublayers))
    }
    set()
    const h = view.map.layers.on("after-changes", set)
    return () => {
      h.remove()
    }
  }, [
    view,
    filter,
    includeGroupSublayers
  ])

  return activeLayers
}

function createGroupSublayers(props: __esri.GroupLayerProperties) {
  return props.layers.map((l: __esri.LayerProperties & { type: LayerType }) => {
    const { type, ...props } = l
    return createLayer(props, type || "feature")
  })
}

export function createLayer<T>(props: __esri.LayerProperties, type: LayerType) {
  let layer = null

  if(assertGroupLayerPropsByType(type, props)) {
    if(props.layers) props.layers = createGroupSublayers(props)
    layer = new GroupLayer(props)
  }
  if(assertFeatureLayerPropsByType(type, props)) {
    layer = new FeatureLayer(props)
  }
  if(assertGraphicsLayerPropsByType(type, props)) {
    layer = new GraphicsLayer(props)
  }
  if(assertMapImageLayerPropsByType(type, props)) {
    layer = new MapImageLayer(props)
  }
  if(assertSceneLayerPropsByType(type, props)) {
    layer = new SceneLayer(props)
  }
  if(assertWFSLayerPropsByType(type, props)) {
    layer = new WFSLayer(props)
  }
  if(assertWMSLayerPropsByType(type, props)) {
    layer = new WMSLayer(props)
  }
  if(assertWMTSLayerPropsByType(type, props)) {
    layer = new WMTSLayer(props)
  }
  if(assertImageryLayerPropsByType(type, props)) {
    layer = new ImageryLayer(props)
  }
  if(assertOGCFeatureLayerPropsByType(type, props)) {
    layer = new OGCFeatureLayer(props)
  }

  for(const k in props) {
    layer[k] = props[k]
  }

  return layer as T
}

// export function useGraphicsAttributesFromLayer<
//   G extends Graphic = Graphic
// >(layer: IGraphicsLayer<G>) {
//   const [ attributes, setAttributes ] = useState<G["attributes"][]>([])
//   useEffect(() => {
//     if(!layer) return
//     const gs = layer.graphics.map((g) => g.attributes).toArray()
//     setAttributes(gs)
//   }, [
//     layer
//   ])
//   return attributes
// }

type LayerTypeToLayer = {
  "group": GroupLayer,
  "graphics": IGraphicsLayer,
  "feature": FeatureLayer,
  "map-image": MapImageLayer,
  "scene": SceneLayer,
  "wfs": WFSLayer,
  "wms": WMSLayer,
  "wmts": WMTSLayer,
  "imagery": ImageryLayer,
  "ogc-feature": OGCFeatureLayer
}

type LayerTypeToProps = {
  "group": GroupLayerProps,
  "graphics": GraphicsLayerProps,
  "feature": FeatureLayerProps,
  "map-image": MapImageLayerProps,
  "scene": SceneLayerProps,
  "wfs": WFSLayerProps,
  "wms": WMSLayerProps,
  "wmts": WMTSLayerProps,
  "imagery": ImageryLayerProps,
  "ogc-feature": OGCFeatureLayerProps
}

export function useLayer<T extends keyof LayerTypeToLayer>(
  props: Partial<LayerTypeToProps[T]>,
  view: ActiveView,
  type: T,
  addToView?: boolean,
  removeOnDismount = true
) {
  type Layer = LayerTypeToLayer[T]
  const [ layer, setLayer ] = useState<Layer>()

  useEffect(() => {
    if(!view || !props || !type) return
    let layer: Layer

    whenOnce(() => view.ready).then(() => {
      layer = findLayer<any>(props.id, view)

      if(layer) {
        setLayer(layer)
      } else {
        layer = createLayer<Layer>(props, type)
        setLayer(layer)

        if(addToView) {
          view.map.add(layer)
        }
      }
    })

    return () => {
      if(addToView && view && layer && removeOnDismount) {
        view.map.remove(layer)
      }
    }
  }, [
      view,
      props,
      type,
      addToView,
      removeOnDismount
    ])

  return layer
}

export function useLayerWatch(
  layer: (__esri.Layer | WMSSublayer) | __esri.Collection<Layer>,
  path: string | string[],
  cb: __esri.WatchCallback,
  sync?: boolean
  // isImmediate?: boolean
) {
  useEffect(() => {
    if(!layer || !cb || !path) return
    if(layer instanceof Collection) {
      const hs = layer.map((layer) => {
        return layer.watch(path, cb, sync)
      })
      return () => {
        for(const h of hs) {
          h.remove()
        }
      }
    } else {
      // if(isImmediate) {
      //   const i = Array.isArray(path)
      //     ? path[0]
      //     : path
      //   const v = layer[i]
      //   cb(v, v, i, null)
      // }
      const h = layer.watch(path, cb, sync)
      return () => {
        h.remove()
      }
    }
  }, [
    path,
    layer,
    cb,
    sync
    // isImmediate
  ])
}

export function useLayerView<T extends __esri.LayerView>(
  view: ActiveView,
  layer: __esri.Layer
) {
  const [ layerView, setLayerView ] = useState<T>(null)

  useEffect(() => {
    if(!view || !layer) return
    whenOnce(() => view.ready && layer.loaded).then(async () => {
      try {
        const lv = await view.whenLayerView(layer) as T
        setLayerView(lv)
      // eslint-disable-next-line keyword-spacing
      } catch {
        setLayerView(null)
      }
    })
  }, [
    view,
    layer
  ])

  return layerView
}

export function useLayerVisibility(layer: Layer) {
  const [ isVisible, setIsVisible ] = useState(true)

  useLayerWatch(layer, "visible", () => {
    setIsVisible(layer.visible)
  })

  const toggleVisibility = useCallback((override?: boolean) => {
    if(!layer) return
    layer.visible = override ?? !layer.visible
  }, [
    layer
  ])

  return {
    isVisible,
    toggleVisibility
  }
}

const defaultGraphicsLayerOptions = {
  centerMapImmediate: true,
  clearOnChange: false
}

export function useGraphicsLayer(
  view: ActiveView,
  graphics?: __esri.Graphic[],
  layerConfig?: __esri.GraphicsLayerProperties,
  options?: Partial<typeof defaultGraphicsLayerOptions>
) {
  const hasGraphics = useMemo(() => graphics?.length > 0, [ graphics ])

  const cfg = useMemo((): __esri.GraphicsLayerProperties => ({
    listMode: "hide",
    ...layerConfig
  }), [
    layerConfig
  ])

  const layer = useLayer(cfg, view, "graphics")

  useEffect(() => {
    if(!layer || !view) return
    view.map.add(layer)
    return () => {
      view.map.remove(layer)
    }
  }, [
    layer,
    view
  ])

  const clearLayer = useCallback(() => {
    if(!layer || !graphics) return
    layer.graphics.removeMany(graphics)
  }, [
    layer,
    graphics
  ])

  useEffect(() => {
    if(!layer || !graphics?.length) return
    if((
      options?.clearOnChange
        ?? defaultGraphicsLayerOptions.clearOnChange
      ) && layer.graphics.length > 0
    ) {
      clearLayer()
    }
    layer.graphics.addMany(graphics)
    return () => {
      layer.graphics.removeMany(graphics)
    }
  }, [
    options,
    clearLayer,
    layer,
    graphics
  ])

  const { isVisible, toggleVisibility } = useLayerVisibility(layer)

  return {
    layer,
    graphics,
    hasGraphics,
    isVisible,
    toggleVisibility,
    centerMap(overrides?: CenterMapGraphics) {
      centerMap(view, overrides || graphics)
    },
    clearLayer
  }
}

export function useGroupLayer(
  layerConfig: __esri.GroupLayerProperties,
  view: ActiveView,
  layers: __esri.Layer[]
) {
  const layer = useLayer(layerConfig, view, "group")

  useEffect(() => {
    if(!layer || !view) return
    view.map.add(layer)
    return () => {
      view.map.remove(layer)
    }
  }, [
    layer,
    view
  ])

  useEffect(() => {
    if(!layer || !layers?.length) return
    layer.addMany(layers)
  }, [
    layer,
    layers
  ])

  return layer
}

export function useSublayers(layer: Layer) {
  const [ sublayers, setSublayers ] = useState<
    Layer[] | __esri.Collection<__esri.WMSSublayer>
  >([])

  useLayerWatch(layer, [ "sublayers.items", "layers" ], (sublayers) => {
    if(assertWMTSLayer(layer)) return
    setSublayers(sublayers)
  })

  useEffect(() => {
    if(assertWMTSLayer(layer)) return

    const l = layer as __esri.GroupLayer
    if(l.layers) {
      setSublayers(l.layers as any)
      return
    }

    const wmsSublayer = layer as any as __esri.WMSSublayer
    if(!wmsSublayer?.sublayers) return
    setSublayers(wmsSublayer.sublayers)
  }, [
    layer
  ])

  return sublayers
}

export function useLayerLoadStatus(layer: __esri.Layer) {
  const [ status, setStatus ] = useState<__esri.Layer["loadStatus"]>("not-loaded")
  useLayerWatch(layer, "loadStatus", setStatus, true)
  return status
}

export function useLayerIsUpdating(
  view: ActiveView,
  layer: __esri.Layer,
  once = false
) {
  const [ isUpdating, setIsUpdating ] = useState(true)

  useEffect(() => {
    if(!view || !layer) return
    let u: __esri.WatchHandle

    layer.when(async () => {
      try {
        const lv = await view.whenLayerView(layer)
        u = lv.watch("updating", (isUpdating) => {
          setIsUpdating(isUpdating)
          if(once && isUpdating === false) {
            u.remove()
          }
        }, true)

        // eslint-disable-next-line keyword-spacing
      } catch {
        // When swapping between 2d/3d, view changes before
        // the component dismounts. This causes the effect to be
        // ran inappropriately, and leads to promise rejection
      }
    })

    return () => {
      if(u) u.remove()
    }
  }, [
    view,
    layer,
    once
  ])

  return isUpdating
}

export function useLayerLoadingIndicator(
  view: ActiveView,
  layer: __esri.Layer
) {
  const isUpdating = useLayerIsUpdating(view, layer)
  const loadStatus = useLayerLoadStatus(layer)
  return isUpdating || loadStatus === "loading"
}

/**
 * Wether or not a layer is displayed is determined by the property "updating".
 * When this is false, it means that the layer is disabled in the map.
 * @param {SceneView} view
 * @param {string[]} layerTitles
 */
export function getIsEveryLayerDisplayed(view: ActiveView, layerTitles: string[]) {
  const displayedLayers = view.allLayerViews.filter((l) => !l.updating)

  const displayedTargetLayers = {}
  for(const layerView of displayedLayers) {
    const title = layerView.layer?.title
    if(title && layerTitles.includes(title)) {
      displayedTargetLayers[title] = true
    }
  }

  return layerTitles
    .every((title) => displayedTargetLayers[title])
}

export function useIsEveryLayerDisplayed(
  view: ActiveView,
  layerTitles: string[]
) {
  const [ isDisplayed, setIsDisplayed ] = useState(false)
  // @note: we need to keep track of this so the consumer
  // can be sure that the isDisplayed is not stale after switching views
  const [ viewType, setViewType ] = useState(view?.type)

  useEffect(() => {
    if(!view) return
    setIsDisplayed(false)

    const wh: __esri.Handle[] = []
    let h: __esri.Handle

    if(layerTitles.length === 0) {
      setIsDisplayed(true)
      setViewType(view.type)
      return
    }

    function removeAllHandlers() {
      if(h) h.remove()
      for(const handle of wh) {
        handle.remove()
      }
    }

    const allTargetLayersDisplayed = getIsEveryLayerDisplayed(view, layerTitles)

    if(allTargetLayersDisplayed) {
      setIsDisplayed(true)
      setViewType(view.type)
    } else {
      // add listeners to the layersViews that are not yet loaded
      const updatingLayers = view.allLayerViews.filter((l) => l.updating)
      for(const layerView of updatingLayers) {
        wh.push(when(() => !layerView.updating, () => {
          const allTargetLayersDisplayed = getIsEveryLayerDisplayed(view, layerTitles)
          if(allTargetLayersDisplayed) {
            setIsDisplayed(true)
            setViewType(view.type)
            removeAllHandlers()
          }
        }))
      }

      // add listeners to incoming layersView
      h = view.allLayerViews.on("after-add", (e) => {
        const layerView = e.item
        wh.push(when(() => !layerView.updating, () => {
          const allTargetLayersDisplayed = getIsEveryLayerDisplayed(view, layerTitles)
          if(allTargetLayersDisplayed) {
            setIsDisplayed(true)
            setViewType(view.type)
            removeAllHandlers()
          }
        }))
      })
    }

    return () => {
      removeAllHandlers()
    }
  }, [
    layerTitles,
    view
  ])

  return { isDisplayed, viewType }
}

export function getIsEveryLayerAdded(view: ActiveView, layerIds: string[]) {
  const addedLayers = view.allLayerViews
  const layerIdsSet = new Set(layerIds)

  const displayedTargetLayers: Record<string, boolean> = {}
  for(const layerView of addedLayers) {
    const id = layerView.layer?.id
    if(id && layerIdsSet.has(id)) {
      displayedTargetLayers[id] = true
    }
  }

  return [ ...layerIdsSet ].every((id) => displayedTargetLayers[id])
}

export function useIsEveryLayerAdded(
  view: ActiveView,
  layerIds: string[]
) {
  const [ isDisplayed, setIsDisplayed ] = useState(false)
  // @note: we need to keep track of this so the consumer
  // can be sure that the isDisplayed is not stale after switching views
  const [ viewType, setViewType ] = useState(view?.type)

  useEffect(() => {
    if(!view) return
    setIsDisplayed(false)

    const wh: __esri.Handle[] = []
    let h: __esri.Handle

    if(layerIds.length === 0) {
      setIsDisplayed(true)
      setViewType(view.type)
      return
    }

    function removeAllHandlers() {
      if(h) h.remove()
      for(const handle of wh) {
        handle.remove()
      }
    }

    const allTargetLayersDisplayed = getIsEveryLayerAdded(view, layerIds)

    if(allTargetLayersDisplayed) {
      setIsDisplayed(true)
      setViewType(view.type)
    } else {

      // add listeners to incoming layersView
      h = view.allLayerViews.on("after-add", (e) => {
          const allTargetLayersDisplayed = getIsEveryLayerAdded(view, layerIds)
          if(allTargetLayersDisplayed) {
            setIsDisplayed(true)
            setViewType(view.type)
            removeAllHandlers()
          }
      })
    }

    return () => {
      removeAllHandlers()
    }
  }, [
    layerIds,
    view
  ])

  return { isDisplayed, viewType }
}

export function assertAndGetRenderer(renderer: __esri.Renderer) {
  if(assertClassBreaksRenderer(renderer)) {
    return renderer
  }
  if(assertDictionaryRenderer(renderer)) {
    return renderer
  }
  if(assertDotDensityRenderer(renderer)) {
    return renderer
  }
  if(assertHeatmapRenderer(renderer)) {
    return renderer
  }
  if(assertPieChartRenderer(renderer)) {
    return renderer
  }
  if(assertSimpleRenderer(renderer)) {
    return renderer
  }
  if(assertUniqueValueRenderer(renderer)) {
    return renderer
  }
}

export function getRendererFromLayer(layer: __esri.Layer) {
  if(
    assertFeatureLayer(layer)
    || assertSceneLayer(layer)
    || assertCSVLayer(layer)
    || assertGeoJSONLayer(layer)
    || assertOGCFeatureLayer(layer)
    || assertStreamLayer(layer)
    || assertWFSLayer(layer)
  ) {
    const renderer = layer.renderer
    return assertAndGetRenderer(renderer)
  }
}

export function getSymbolFromRenderer(renderer: __esri.Renderer) {
  if(assertSimpleRenderer(renderer)) {
    const symbol = renderer.symbol
    if(assertSimpleFillSymbol(symbol)) {
      return symbol
    }
    if(assertSimpleLineSymbol(symbol)) {
      return symbol
    }
    if(assertMeshSymbol3D(symbol)) {
      return symbol
    }
  }
}

export function getColorFromSymbol(symbol: __esri.Symbol) {
  if(assertSimpleFillSymbol(symbol)) {
    return symbol.color
  }
  if(assertSimpleLineSymbol(symbol)) {
    return symbol.color
  }
  if(assertMeshSymbol3D(symbol)) {
    // we can improve on this,
    // but for now we'll just return the first color
    const colors = getColorsFrom3DMeshSymbol(symbol)
    if(colors && colors.length > 0) {
      return colors.toArray()[0] ?? colors.toArray()[1]
    }
  }
}

export function getEdgeColorFromSymbol(symbol: __esri.Symbol) {
  if(assertSimpleFillSymbol(symbol)) {
    return symbol.outline.color
  }
  if(assertSimpleLineSymbol(symbol)) {
    return symbol.color
  }
  if(assertMeshSymbol3D(symbol)) {
    // we can improve on this,
    // but for now we'll just return the first color
    const colors = getEdgeColorsFrom3DMeshSymbol(symbol)
    if(colors && colors.length > 0) {
      return colors.toArray()[0] ?? colors.toArray()[1]
    }
  }
}

export function getEdgeSettingsFromSymbol(symbol: __esri.Symbol) {
  if(assertSimpleFillSymbol(symbol)) {
    return symbol.outline
  }
  if(assertSimpleLineSymbol(symbol)) {
    return
  }
  if(assertMeshSymbol3D(symbol)) {
    // we can improve on this,
    // but for now we'll just return the first color
    const settings = getEdgeSettingsFrom3DMeshSymbol(symbol)
    if(settings && settings.length > 0) {
      return settings.at(0)
    }
  }
}

export function getColorsFromVisualVariables(
  visualVariables: __esri.VisualVariable[]
) {
  const collection = new Collection<__esri.Color>()
  for(const visualVariable of visualVariables) {
    if(assertColorVariable(visualVariable)) {
      for(const stop of visualVariable.stops) {
        collection.add(stop.color)
      }
    }
  }
  return collection
}

export function getEdgeColorsFromVisualVariables(
  visualVariables: __esri.VisualVariable[]
) {
  const collection = new Collection<__esri.Color>()
  for(const visualVariable of visualVariables) {
    if(assertColorVariable(visualVariable)) {
      for(const stop of visualVariable.stops) {
        collection.add(stop.color)
      }
    }
  }
  return collection
}

export function getColorFromSceneLayer(layer: __esri.Layer) {
  if(!layer) return null
  if(assertSceneLayer(layer)) {
    const visualVariables = getVisualVariablesFromLayer(layer)
    if(visualVariables) {
      // When we support it, we should iterate over the visual
      // variables and check if any of them have a color
      // and return an array of colors
      return getColorsFromVisualVariables(visualVariables).toArray()[0]
    }
    const renderer = getRendererFromLayer(layer)
    const symbol = getSymbolFromRenderer(renderer)
    if(symbol) {
      return getColorFromSymbol(symbol)
    }
  }
}

export function getEdgeColorFromSceneLayer(layer: __esri.Layer) {
  if(!layer) return null
  if(assertSceneLayer(layer)) {
    const renderer = getRendererFromLayer(layer)
    const symbol = getSymbolFromRenderer(renderer)
    if(symbol) {
      return getEdgeColorFromSymbol(symbol)
    }
  }
}

export function getEdgeSettingsFromSceneLayer(layer: __esri.Layer) {
  if(!layer) return null
  if(assertSceneLayer(layer)) {
    const renderer = getRendererFromLayer(layer)
    const symbol = getSymbolFromRenderer(renderer)
    if(symbol) {
      return getEdgeSettingsFromSymbol(symbol)
    }
  }
}

const LOAD_STATUSES = {
  NOT_LOADED: "not-loaded",
  LOADING: "loading",
  LOADED: "loaded",
  FAILED: "failed"
} as const

export type LOAD_STATUS = Value<typeof LOAD_STATUSES>

export function useLoadStatusByLayerId(id: string, view: ActiveView) {
  const [ loadStatus, setLoadStatus ] = useState<LOAD_STATUS>(LOAD_STATUSES.NOT_LOADED)
  const layer = useFindLayer(id, view)

  useEffect(() => {
    if(!layer) {
      setLoadStatus(LOAD_STATUSES.NOT_LOADED)
      return
    }
    setLoadStatus(layer.loadStatus)
  }, [ layer ])

  useLayerWatch(layer, "loadStatus", setLoadStatus, true)

  return loadStatus
}

export function useIsLayerLoaded(id: string, view: ActiveView) {
  return useLoadStatusByLayerId(id, view) === LOAD_STATUSES.LOADED
}

export function useIsLayerLoading(id: string, view: ActiveView) {
  return useLoadStatusByLayerId(id, view) === LOAD_STATUSES.LOADING
}

export function useLayerEnabledByLayerId(id: string, view: ActiveView) {
  return useIsLayerLoaded(id, view)
}

export function useLayerLoadErrorByLayerId(id: string, view: ActiveView) {
  const [ error, setError ] = useState<Error>()
  const layer = useFindLayer(id, view)
  useEffect(() => {
    if(!layer) return
    setError(layer.loadError)
  }, [ layer ])

  useLayerWatch(layer, "loadError", setError, true)

  return error
}
