import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"
import type { LayerType } from "@/v2-map-ui/map.types"

export type WFSLayerProps = WithMeta<__esri.WFSLayerProperties>
export type WFSLayer = WithMeta<__esri.WFSLayer>

export function assertWFSLayer(
  layer: __esri.Layer
): layer is __esri.WFSLayer {
  return layer.type === "wfs"
}

export function assertWFSLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is WFSLayerProps {
  return type === "wfs"
}
