import type { LayerType } from "@/v2-map-ui/map.types"
import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"
import type { WMTSSublayer }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.wmtsSublayer"

export type WMTSLayerProps = WithMeta<__esri.WMTSLayerProperties>
export type WMTSLayer = WithMeta<Exclude<__esri.WMTSLayer, "sublayers" | "allSublayers">> & {
  sublayers: __esri.Collection<WMTSSublayer>,
  allSublayers: __esri.Collection<WMTSSublayer>
}

export function assertWMTSLayer(
  layer: __esri.Layer
): layer is __esri.WMTSLayer {
  return layer.type === "wmts"
}

export function assertWMTSLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is WMTSLayerProps {
  return type === "wmts"
}
