import type { FieldPolicy } from "@apollo/client"

export const getTagById: FieldPolicy = {
  read(existing, { args, toReference }) {
    if(!args || !args.Id) return existing
    return toReference({
      __typename: "Tag",
      Id: args.Id
    })
  }
}
