import { forwardRef } from "react"
import type { BadgeProps } from "@/v2-ui/badge"
import type { IconProps } from "@/v2-ui/icon"
import cn from "@/v2-ui/utils/utils.cn"
import Badge from "@/v2-ui/badge"
import Icon from "@/v2-ui/icon"

type BaseProps = BadgeProps

export type BadgeWithIconProps = BaseProps & {
  iconSrc: IconProps["src"],
  iconSize?: IconProps["size"],
  iconClassName?: IconProps["className"],
  isLoading?: IconProps["loading"]
}

function BadgeWithIcon(props: BadgeWithIconProps, ref) {
  const {
    iconSrc,
    iconSize = 10,
    iconClassName,
    isLoading,
    children,
    isSkeleton,
    ...badgeProps
  } = props

  return (
    <Badge
      isSkeleton={isSkeleton}
      {...badgeProps}
      ref={ref}
    >
      <Icon
        src={iconSrc}
        size={iconSize}
        className={cn("mr-1", iconClassName)}
        loading={isLoading}
      />
      {children}
    </Badge>
  )
}

export default forwardRef(BadgeWithIcon)
