import { useLocation } from "react-router-dom"
import Sidebar from "@/v2-layout-ui/sidebar"
import { useNavigate } from "@/v2-console-shared/utils/utils.router"
import config from "@/v2-console-shared/config"
import { useRootText } from "@/v2-console-shared/texts"
import AppSidebarCurrentUserItem from "@/v2-console/app/sidebar/AppSidebarCurrentUserItem"
import AppSidebarItemCountry from "@/v2-console/app/sidebar/AppSidebarItemCountry"
import { trackAppSidebarNavigated } from "@/v2-console/app/app.tracking"
import { useSidebarContext }
  from "@/v2-layout-ui/sidebar"
import { useCallback } from "react"
import { useOnAppSidebarClose } from "./AppSidebar.utils"

function AppSidebar() {
  const navigate = useNavigate()
  const location = useLocation()

  const { setIsMini } = useSidebarContext()
  const { loading, getText } = useRootText()

  function getIsActiveByPath(path: string) {
    return location.pathname.includes(path)
  }

  const onMinifySidebar = useCallback(() => {
    setIsMini(true)
  }, [ setIsMini ])

  /*
  * @note: using events instead of context so that the caller
  * is not dependent on the isMini and thus need to rerender on call.
  * Such re-renderes disturp resize events, and dont let the caller
  * continue to resize
  */
  useOnAppSidebarClose(onMinifySidebar)

  return (
    <Sidebar>

      <Sidebar.Branding />

      <Sidebar.Content>
        <Sidebar.Group>
          <Sidebar.Item
            className="app-sidebar__to-layers"
            iconSrc="layers"
            label={getText("UI_Layers_Root").Name}
            isActive={getIsActiveByPath("map/layers")}
            onClick={() => {
              if(getIsActiveByPath("map/layers")) {
                navigate("/map")
              }
              if(!getIsActiveByPath("map/layers")) {
                navigate("/map/layers")
              }
              trackAppSidebarNavigated("Layers")
            }}
            isLoading={loading}
          />

          <Sidebar.Item
            className="app-sidebar__to-tags"
            iconSrc="tags"
            label={getText("UI_Tags_Root").Name}
            isActive={getIsActiveByPath("map/tags")}
            onClick={() => {
              if(getIsActiveByPath("map/tags")) {
                navigate("/map")
              }
              if(!getIsActiveByPath("map/tags")) {
                navigate("/map/tags")
              }
              trackAppSidebarNavigated("Tags")
            }}
            isLoading={loading}
          />
        </Sidebar.Group>

        <Sidebar.Group className="mt-auto">
          <AppSidebarItemCountry
            className="app-sidebar__to-countrySwitcher pr-2"
            isLoading={loading}
          />
          <Sidebar.Item
            className="app-sidebar__to-settings"
            iconSrc="settings"
            isActive={getIsActiveByPath("user/settings")}
            onClick={() => {
              if(getIsActiveByPath("/map/user/settings")) {
                navigate("/map")
              }
              if(!getIsActiveByPath("/map/user/settings")) {
                navigate("/map/user/settings")
              }
              trackAppSidebarNavigated("Settings")
            }}
            isLoading={loading}
            label={getText("UI_Settings_Root").Name}
          />
          <Sidebar.Item
            className="app-sidebar__to-support"
            iconSrc="interrogation"
            onClick={() => {
              window.open(config.docsUrl)
              trackAppSidebarNavigated("Docs")
            }}
            isLoading={loading}
            label={getText("UI_Help_Root").Name}
          />
        </Sidebar.Group>

      </Sidebar.Content>
      <Sidebar.Separator />
      <Sidebar.Footer>
        <AppSidebarCurrentUserItem
          loading={loading}
        />
      </Sidebar.Footer>
    </Sidebar>
  )
}

export default AppSidebar
