import type { LayerType } from "@/v2-map-ui/map.types"
import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"

export type FeatureLayerProps = WithMeta<__esri.FeatureLayerProperties>
export type FeatureLayer = WithMeta<__esri.FeatureLayer>

export function assertFeatureLayer(
  layer: __esri.Layer
): layer is FeatureLayer {
  return layer.type === "feature"
}

export function assertFeatureLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is FeatureLayerProps {
  return type === "feature"
}
