import { forwardRef, type ComponentProps } from "react"
import { CommandInput as CommandInputBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandInputProps = ComponentProps<typeof CommandInputBase>

function CommandInput(props: CommandInputProps, ref) {
  const { className, ...commandInputBaseProps } = props
  return (
    <CommandInputBase
      ref={ref}
      className={cn("ui-command-input", className)}
      {...commandInputBaseProps}
    />
  )
}

export default forwardRef(CommandInput)
