import type { ComponentProps } from "react"

import { SheetPortal, SheetContent as SheetContentBase }
  from "@/v2-ui/sheet/base/SheetBase"
import SheetOverlay
  from "@/v2-ui/sheet/SheetOverlay"
import { useQuerySelectorLazy }
  from "@/v2-ui/utils/utils.dom"
import cn from "@/v2-ui/utils/utils.cn"

const DEAFAULT_PORTAL_CONTAINER = "#mount"

export type SheetContentProps = ComponentProps<typeof SheetContentBase> & {
  portalContainer?: string
  showOverlay?: boolean
}

function SheetContent(props: SheetContentProps) {
  const {
    portalContainer = DEAFAULT_PORTAL_CONTAINER,
    showOverlay = true,
    className,
    ...sheetContentBaseProps
  } = props

  const portalElem = useQuerySelectorLazy(portalContainer)

  return (
    <SheetPortal
      container={portalElem}
    >
      {showOverlay ? <SheetOverlay /> : null}
      <SheetContentBase
        className={cn("ui-sheet-content", className)}
        {...sheetContentBaseProps}
      />
    </SheetPortal>
  )
}

export default SheetContent
