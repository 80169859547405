import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"
import type { LayerType } from "@/v2-map-ui/map.types"
import type { Sublayer } from "@/v2-map-ui/layer/mixins/map.layer.mixins.sublayer"

export type MapImageLayerProps = WithMeta<__esri.MapImageLayerProperties>
export type MapImageLayer = Exclude<WithMeta<__esri.MapImageLayer>, "sublayers" | "allSublayers"> & {
  sublayers: __esri.Collection<Sublayer>,
  allSublayers: __esri.Collection<Sublayer>
}

export function assertMapImageLayer(
  layer: __esri.Layer
): layer is MapImageLayer {
  return layer.type === "map-image"
}

export function assertMapImageLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is MapImageLayerProps {
  return type === "map-image"
}
