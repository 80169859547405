import { makeVar, useReactiveVar } from "@apollo/client"
import { safeJSONParse } from "@/v2-common/utils/utils.misc"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"

const NAV_LAYER_SETTINGS_STORAGE_KEY = "navLayerSettings"

export type NavLayerSettings = {
  activeId?: string,
  minScale?: number
}

export type NavLayerSettingsByCountryCode = Record<number, NavLayerSettings>

const DEFAULT_NAV_LAYER_SETTINGS: NavLayerSettingsByCountryCode = {}

const NAV_LAYER_SETTINGS_DEFAULT_VALUE = localStorage[NAV_LAYER_SETTINGS_STORAGE_KEY] ?? DEFAULT_NAV_LAYER_SETTINGS

export const navLayerSettingVar = makeVar<NavLayerSettingsByCountryCode>(safeJSONParse(NAV_LAYER_SETTINGS_DEFAULT_VALUE))

// @note: abstracts away the country code logic for the consumer
export function updateNavLayerSettings(currentCountrySettings: NavLayerSettings, countryCode: number) {
  const allCountriesSettings = navLayerSettingVar()

  // update only the currentCountry settings
  const updatedSettings = {
    ...allCountriesSettings,
    [countryCode]: {
      ...(allCountriesSettings?.[countryCode]),
      ...currentCountrySettings
    }
  }

  // persist to localstorage
  const strifiedSettings = JSON.stringify(updatedSettings)
  localStorage[NAV_LAYER_SETTINGS_STORAGE_KEY] = strifiedSettings

  const newSettings = navLayerSettingVar(updatedSettings)
  return newSettings[countryCode]
}

export function useNavLayerSettings() {
  const { currentUser } = useAppContext()
  const navLayerSettings = useReactiveVar(navLayerSettingVar)
  return {
    navLayerSettings: currentUser && navLayerSettings?.[currentUser?.ActiveCountryCode],
    isNavLayerSettingsLoading: !currentUser?.ActiveCountryCode
  } as const
}

/**
 * A reactive variable to store the temporary disabled state of the navigation layer.
 * This is used to have a non-persistent way to disable the navigation layer.
 * After a refresh it will go back, unlike above settigns
 * @default false
 */
export const navLayerTempDisabledVar = makeVar<boolean>(false)

/**
 * Toggles the temporary disabled state of the navigation layer.
 * Flips the current value of `navLayerTempDisabledVar`.
 * @returns The updated state of `navLayerTempDisabledVar`.
 */
export function toggleNavLayerTempDisabled() {
  return navLayerTempDisabledVar(!navLayerTempDisabledVar())
}

/**
 * Hook to retrieve the current state of the navigation layer's temporary disabled state.
 * @returns The current state of `navLayerTempDisabledVar`.
 */
export function useNavLayerTempDisabled() {
  const navLayerTempDisabled = useReactiveVar(navLayerTempDisabledVar)
  return navLayerTempDisabled
}
