import { useCallback, useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"
import {
  getCountryCodeFromHashParam,
  updateUrlWithViewpointOnCountrySwitch
} from "@/v2-console/map/map.viewpoint"
import { useCadastrePanel }
  from "@/v2-console/cadastre/cadastre.utils"
import { useCurrentUserCountryLazyQuery }
  from "@/v2-console/currentUser/country/currentUser.country.query"
import useCurrentUserCountryUpdateMutation
  from "@/v2-console/currentUser/country/update/currentUser.country.update.mutation"
import type { CurrentUserCountryFragment }
  from "@/v2-console-shared/currentUser/country/__types__/currentUser.country.fragment"
import { trackCurrentUserCountryChanged }
  from "@/v2-console/currentUser/currentUser.tracking"

/**
 * Hook to manage the current user's active country state.
 * Retrieves available countries and handles country switching logic.
 *
 * @returns Active country state, loading/error states, countries list, and country change handler.
 */
export const useCurrentUserCountry = () => {

  const [ activeCountry, setActiveCountry ] = useState<CurrentUserCountryFragment>()

  const { currentUser } = useAppContext()
  const { close } = useCadastrePanel()

  const [ getCountriesQuery, { data, error: queryError, loading: isQueryLoading }]
    = useCurrentUserCountryLazyQuery()

  const onCountryChangeComplete = useCallback((
    oldCountryCode: number,
    newCountryCode: number
  ) => {
    setActiveCountry(null)
    // @note: crucial in order to update the map viewpoint
    // to the last known position (if any) in the new country
    // This will also store the current viewpoint for the country
    // we are leaving
    updateUrlWithViewpointOnCountrySwitch(
      oldCountryCode,
      newCountryCode
    )

    // close the cadastre panel in case it is open
    close()

    // track country change
    trackCurrentUserCountryChanged(newCountryCode?.toString())
  }, [ close ])

  const {
    onCountryChange,
    error: updateQueryError,
    loading: isUpdateQueryLoading
  } = useMutateCountryCode(currentUser?.ActiveCountryCode, onCountryChangeComplete)

  useEffect(() => {
    if(!currentUser) return
    getCountriesQuery()
  }, [ getCountriesQuery, currentUser ])

  useEffect(() => {
    if(!data?.getCountries || !currentUser) return
    const matchedCountry = data.getCountries.find(
      country => country.Code === currentUser.ActiveCountryCode
    )
    setActiveCountry(matchedCountry)
  }, [ currentUser, data?.getCountries ])

  const loading = !activeCountry || isQueryLoading || isUpdateQueryLoading
  const error = queryError || updateQueryError
  const countries = data?.getCountries || []

  return {
    activeCountry,
    loading,
    error,
    countries,
    onCountryChange
  }

}

/**
 * Wrapper for mutation to update the current country code.
 *
 * @param currentCountryCode - The current active country code.
 * @param onComplete - Callback to execute after a successful mutation.
 * @returns Mutation loading/error states and the country change handler.
 */
export function useMutateCountryCode(
  currentCountryCode: number,
  onComplete?: (oldCountryCode: number, newCountryCode: number) => void
) {

  const client = useApolloClient()

  const [ updateCountryMutation, { error, loading }]
    = useCurrentUserCountryUpdateMutation()

  const onCountryChange = useCallback((countryCode: number) => {

    updateCountryMutation({
      variables: {
        input: {
          countryCode: countryCode
        }
      },
      onCompleted: () => {
        // @note: we need to reset client cache in order to refetch
        // all queries  with the new country
        client.resetStore()
        onComplete?.(currentCountryCode, countryCode)
      }
    })
  }, [
    updateCountryMutation,
    client,
    currentCountryCode,
    onComplete
  ])

  return {
    loading,
    error,
    onCountryChange
  }
}

/**
 * Hook to validate the country code from the URL and switch the user's active country if necessary.
 */
export function useCheckCountryCodeFromUrl() {
  const { currentUser } = useAppContext()

  const {
    onCountryChange,
    loading
  } = useMutateCountryCode(currentUser?.ActiveCountryCode)

  useEffect(() => {
    if(!currentUser?.ActiveCountryCode || loading) return
    const urlCountryCode = +getCountryCodeFromHashParam()
    if(urlCountryCode && currentUser?.ActiveCountryCode !== urlCountryCode) {
      // @note: we do not pass in a oncomplete callback here as we dont want to
      // touch the hash params or anything...
      onCountryChange(urlCountryCode)
    }
  }, [ currentUser, loading, onCountryChange ])
}
