import "./icon.custom.viewshed.css"
import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"

export type IconCustomViewshedProps = ComponentProps<"svg">
function IconCustomViewshed(props: IconCustomViewshedProps) {
  const {
    className,
    viewBox = "0 0 14 18",
    fill = "none",
    width = 14,
    height = 18,
    ...svgProps
  } = props

  return (
    <svg
      className={cn("viewshed", className)}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...svgProps}
    >
      <path className="viewshed_svg_path" d="M12.0762 0.916655L12.0761 0.916678C11.7949 0.989214 11.6258 1.27583 11.6983 1.55697L11.8873 2.29036C11.8965 2.32585 11.8844 2.35463 11.8527 2.37291L11.8526 2.37293L1.16285 8.54474C1.00022 8.63864 0.9 8.81213 0.9 9C0.9 9.18787 1.00022 9.36135 1.16285 9.45525L1.21285 9.36865L1.16285 9.45525L11.8527 15.6271L11.8527 15.6271C11.8844 15.6454 11.8965 15.6741 11.8873 15.7095L11.8873 15.7096L11.6983 16.443C11.6258 16.7241 11.7949 17.0108 12.0761 17.0833L12.0762 17.0833C12.1199 17.0945 12.164 17.1 12.2076 17.1C12.4416 17.1 12.6552 16.9427 12.7163 16.7054C12.7163 16.7054 12.7164 16.7054 12.7164 16.7054L12.9054 15.972C13.0329 15.4773 12.8208 14.9719 12.3783 14.7165L11.7928 14.3784C13.0851 13.0181 13.8368 11.0604 13.8368 8.99994C13.8368 6.93954 13.0851 4.98188 11.7929 3.62151L12.3783 3.28349L12.3283 3.19689L12.3783 3.28349C12.8208 3.02802 13.0329 2.52265 12.9054 2.02793L12.7163 1.29451C12.6439 1.01332 12.3571 0.84446 12.0762 0.916655ZM12.7854 9C12.7854 9.62978 12.7055 10.247 12.5542 10.8333C12.3651 10.2487 12.2671 9.62981 12.2671 9C12.2671 8.37023 12.3651 7.75138 12.5542 7.16667C12.7055 7.75298 12.7854 8.37018 12.7854 9ZM11.2157 9C11.2157 10.1461 11.492 11.2645 12.0173 12.2529C11.7249 12.8223 11.3558 13.3353 10.9202 13.7677C9.74759 12.6039 9.05509 10.8576 9.05509 9C9.05509 7.1424 9.74759 5.39608 10.9202 4.23231C11.3558 4.66465 11.725 5.17765 12.0173 5.74709C11.492 6.73544 11.2157 7.85387 11.2157 9ZM8.86682 12.6891L2.47708 9L8.86682 5.31085C8.30999 6.41289 8.00365 7.68511 8.00365 9C8.00365 10.3149 8.30999 11.5871 8.86682 12.6891Z" />
    </svg>
  )
}

export default IconCustomViewshed
