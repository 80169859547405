import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CADASTRE_PROPERTY_TEXTS, { type CadastrePropertyTexts }
from "@/v2-console-ui/cadastre/property/cadastreProperty.text"
import type { CadastreModuleContentInputProps }
  from "@/v2-console/cadastre/cadastre.types"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"
import CadastreModuleLoadingFallback
  from "@/v2-console/cadastre/module/CadastreModuleLoadingFallback"

const CadastreProperty = lazy(() => lazyRetry(() => import(
  "@/v2-console/cadastre/module/property/CadastreModuleProperty"
)))

type CadastreModulePropertySuspenderProps = CadastreModuleContentInputProps

function CadastreModulePropertySuspender(props: CadastreModulePropertySuspenderProps) {

  return (
    <FeatureProvider<CadastrePropertyTexts>
      code="Property_Cadastre"
      fallbackTexts={CADASTRE_PROPERTY_TEXTS}
      loadingFallback=<CadastreModuleLoadingFallback />
      isLazyLoaded
    >
      <CadastreProperty
        {...props}
      />
    </FeatureProvider>
  )
}

export default CadastreModulePropertySuspender
