import { useState } from "react"
import Button from "@/v2-ui/button"
import Dialog from "@/v2-ui/dialogAdvanced"
import { getAssetUrl } from "@/v2-ui/utils/utils.assets"
import Tooltip from "@/v2-ui/tooltip"

const imgSrc = getAssetUrl("images/icons/generic-error.svg")

type ErrorFallbackProps = {
  error: Error,
  componentStack: string | null,
  eventId: string | null,
  resetError(): void
}

function ErrorGeneric(props: ErrorFallbackProps) {
  const { error, componentStack } = props
  const [ detailsIsShown, setDetailsIsShown ] = useState(false)

  return (
    <Tooltip.Provider>
      <div className="error-generic text-foreground h-screen max-w-lg mx-auto flex justify-center flex-col text-center pb-24">
        <img
          alt="En feil oppstod"
          src={imgSrc}
          className="w-24 mx-auto"
        />

        <div className="mt-12 mx-auto">
          <div className="text-xl mb-3">
            En feil oppstod
          </div>

          <div className="text-red-600">
            {error.toString()}
          </div>
        </div>

        <div className="my-12">
          <p>
            Det ser ut som et ukjent problem har oppstått, som førte til at appen kræsjet. Vi får automatiske varsler når dette skjer, og har mulighet til å spore feilen. Vi vil se på en løsning omgående, og rulle ut en fiks så snart det lar seg gjøre.
          </p>

          <p className="mt-4">
            Takk for tålmodigheten og beklager ulempen dette medførte!
          </p>
        </div>

        <div className="mx-auto">
          <Button
            className="error-generic__open-dialog"
            onClick={() => {
              setDetailsIsShown(true)
            }}
          >
            Se detaljer
          </Button>

          <Button
            className="error-generic__reload ml-2"
            variant="default"
            onClick={() => {
              window.location.reload()
            }}
          >
            Restart
          </Button>
        </div>
      </div>

      <Dialog
        width={600}
        className="error-generic-dialog"
        isShown={detailsIsShown}
        onHide={() => {
          setDetailsIsShown(false)
        }}
      >
        <Dialog.Header>
          Feilmelding
          <Dialog.Header.Actions />
        </Dialog.Header>

        <Dialog.Body>
          <pre className="text-xs whitespace-pre-wrap pb-6">
            {componentStack}
          </pre>
        </Dialog.Body>
      </Dialog>
    </Tooltip.Provider>
  )
}

export default ErrorGeneric
