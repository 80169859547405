import { useCallback } from "react"
import { safeJSONParse } from "@/v2-common/utils/utils.misc"
import useLocalStorage from "@/v2-ui/utils/utils.localStorage"
import { thousandSeparator } from "@/v2-ui/utils/utils.formatting"

type formatOptions = {
  suffix?: "percentage" | "meterSquare"
  isThousandSeparator?: boolean
}

const SuffixFormat = {
  "percentage": "%",
  "meterSquare": "m<sup>2</sup>"
}

export function formatCadastreValue(
  input?: string | number | null | undefined,
  options?: formatOptions
) {
  if(input === null || input === undefined) return "-"

  const { suffix, isThousandSeparator } = options || {}

  if(typeof input === "number" && isThousandSeparator) {
    input = thousandSeparator(input?.toLocaleString())
  }

  if(suffix) {
    return `<span>${input} ${SuffixFormat[suffix]}</span>`
  }

  return input
}

export const CADASTRE_PANEL_LAYOUT_STORAGE_KEY = "cadastrePanelLayout"

export type CadastrePanelSide = "left" | "right"

export type CadastrePanelLayout = {
  side: CadastrePanelSide,
  width: number
}

export const DEFAULT_CADASTRE_PANEL_LAYOUT_STATE: CadastrePanelLayout = {
  side: "right",
  width: 320 // @todo: design have not accounted for all panels so thats why hardocing cadastrepanel only
}

/**
 * Retrieves the saved layout configuration for the cadastre panel from localStorage.
 *
 * @returns The saved layout configuration if it exists and is valid,
 * otherwise returns the default layout state.
 */
export function getCadastrePanelLayout() {
  const storage = localStorage.getItem(CADASTRE_PANEL_LAYOUT_STORAGE_KEY)
  if(!storage) return DEFAULT_CADASTRE_PANEL_LAYOUT_STATE
  return safeJSONParse<CadastrePanelLayout>(storage)
}

/**
 * Manages the layout state for the cadastre panel, including side and width.
 *
 * - Uses localStorage to persist state.
 * - Provides utilities to update the panel's layout side and width.
 *
 * @returns An object with the current layout state and functions to update the side and width.
 *
 * @note: this could be made generic but dont know if we want to do that yet
 *
 */
export function useCadastrePanelLayout() {
  const { value: layoutState, setItem: setLayoutState } = useLocalStorage<CadastrePanelLayout>(
    CADASTRE_PANEL_LAYOUT_STORAGE_KEY,
    DEFAULT_CADASTRE_PANEL_LAYOUT_STATE
  )

  const setLayoutSide = useCallback((side: CadastrePanelSide) => {
    setLayoutState({
      ...layoutState,
      side
    })
  }, [ layoutState, setLayoutState ])

  const setLayoutWidth = useCallback((width: number) => {
    setLayoutState({
      ...layoutState,
      width
    })
  }, [ layoutState, setLayoutState ])

  return {
    layoutState,
    setLayoutSide,
    setLayoutWidth
  }
}
