import type { LayerType } from "@/v2-map-ui/map.types"
import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"

export type GraphicsLayerProps = WithMeta<__esri.GraphicsLayerProperties>
export type GraphicsLayer = WithMeta<__esri.GraphicsLayer>

export function assertGraphicsLayer(
  layer: __esri.Layer
): layer is GraphicsLayer {
  return layer?.type === "graphics"
}

export function assertGraphicsLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is GraphicsLayerProps {
  return type === "graphics"
}
