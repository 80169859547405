import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"
import type { LayerType } from "@/v2-map-ui/map.types"

export type SceneLayerProps = WithMeta<__esri.SceneLayerProperties>
export type SceneLayer = WithMeta<__esri.SceneLayer> & {
  old?: __esri.SceneLayerProperties
}

export function assertSceneLayer(
  layer: __esri.Layer | Partial<__esri.Layer>
): layer is SceneLayer {
  if(!layer?.type) return false
  return layer.type === "scene"
}

export function assertSceneLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is SceneLayerProps {
  return type === "scene"
}
