import SceneLayer from "@arcgis/core/layers/SceneLayer"
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer"
import Color from "@arcgis/core/Color"
import ColorVariable from "@arcgis/core/renderers/visualVariables/ColorVariable"
import LabelClass from "@arcgis/core/layers/support/LabelClass"
import LabelSymbol3D from "@arcgis/core/symbols/LabelSymbol3D"
import TextSymbol3DLayer from "@arcgis/core/symbols/TextSymbol3DLayer"
import SolidEdges3D from "@arcgis/core/symbols/edges/SolidEdges3D.js"
import MeshSymbol3D from "@arcgis/core/symbols/MeshSymbol3D"
import FillSymbol3DLayer from "@arcgis/core/symbols/FillSymbol3DLayer"
import type { ActiveView } from "@/v2-map-ui/map.types"
import {
  WKID,
  BUILDING_SCENE_LAYER_ID,
  BUILDING_SUB_SCENE_LAYER_ID,
  BUILDINGS_SCENE_LAYER_URL
} from "@/v2-map-ui/map.constants"

export function assertSceneView(view: ActiveView): view is __esri.SceneView {
  return view?.type === "3d"
}

export const BUILDING_LAYER_BORDER = new SolidEdges3D({
  color: new Color([ 0, 0, 0, 0.6 ]),
  size: 1,
  extensionLength: 0
})

export const BUILDING_LAYER_CONFIG = {
  visible: true,
  legendEnabled: false,
  outFields: [ "*" ],
  spatialReference: { wkid: WKID.ui },
  popupEnabled: false,
  minScale: 10000,
  labelsVisible: false,
  labelingInfo: [
    new LabelClass({
      labelExpressionInfo: {
        expression: "$feature.bygningstype"
      },
      symbol: new LabelSymbol3D({
        symbolLayers: [
          new TextSymbol3DLayer({
            size: 10,
            material: {
              color: new Color("white")
            }
          })
        ],
        callout: {
          type: "line",
          size: 1,
          color: new Color("white")
        },
        verticalOffset: {
          screenLength: 20,
          maxWorldLength: 20,
          minWorldLength: 5
        }
      })
    })
  ]
}

/**
 * The building layers should only be added to the view after the token
 * has been injected into the arcgis SDK. To avoid a circular dependency,
 * this function is called from inside the app, insteadof directly inside
 * the createSceneView function
 */
export function addBuildingLayersToScene(sceneView: __esri.SceneView) {
  const buildingLayer = new SceneLayer({
    ...BUILDING_LAYER_CONFIG,
    title: "Bygninger",
    id: BUILDING_SCENE_LAYER_ID,
    url: `${BUILDINGS_SCENE_LAYER_URL}/layers/0`,
    listMode: "hide"
  })

  const simpleBuildingLayer = new SceneLayer({
    ...BUILDING_LAYER_CONFIG,
    title: "Enkle bygninger",
    id: BUILDING_SUB_SCENE_LAYER_ID,
    url: `${BUILDINGS_SCENE_LAYER_URL}/layers/1`,
    listMode: "hide"
  })

  sceneView.map.addMany([
    buildingLayer,
    simpleBuildingLayer
  ])
}
