import { forwardRef } from "react"
import {
  type BadgeProps as BadgeBaseProps,
  Badge as BadgeBase
} from "@/v2-ui/badge/base/BadgeBase"
import cn from "@/v2-ui/utils/utils.cn"
import Skeleton from "@/v2-ui/skeleton"

export type BadgeProps = BadgeBaseProps & {
  isSkeleton?: boolean
}

function Badge(props: BadgeProps, ref) {
  const { isSkeleton, className, ...badgeBaseProps } = props

  if(isSkeleton) {
    return (
      <Skeleton
        className={cn("ui-badge ui-badge__skeleton", className)}
      >
        {/* @Note: to get the same height as a badge */}
        &nbsp;
      </Skeleton>
    )
  }
  return (
    <BadgeBase
      ref={ref}
      className={cn("ui-badge", className)}
      {...badgeBaseProps}
    />
  )
}

export default forwardRef(Badge)
