import type { ComponentProps } from "react"
import { SheetDescription as SheetDescriptionBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"
import ConditionalWrapper
  from "@/v2-ui/ConditionalWrapper"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"

export type SheetDescriptionProps = ComponentProps<typeof SheetDescriptionBase> & {
  isVisuallyHidden?: boolean
}

function SheetDescription(props: SheetDescriptionProps) {
  const { isVisuallyHidden, className, ...sheetDescriptionBaseProps } = props
  return (
    <ConditionalWrapper
      condition={isVisuallyHidden}
      renderWrapper={(children) => (
        <VisuallyHidden.Root>
          {children}
        </VisuallyHidden.Root>
      )}
    >
      <SheetDescriptionBase
        className={cn("ui-sheet-description", className)}
        {...sheetDescriptionBaseProps}
      />
    </ConditionalWrapper>
  )
}

export default SheetDescription
