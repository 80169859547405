import { useText } from "@/v2-ui/text/text.context"
import { type RootTexts } from "@/v2-console-shared/texts"

const CADASTRE_BUILDING_TEXTS = {
  "Property_Building_Header": {
    "Name": "Building Details"
  },
  "Property_Building_Count": {
    "Name": "Building count"
  },
  "Property_Building_AverageFloorCount": {
    "Name": "Average floor count"
  },
  "Property_Building_GrossArea": {
    "Name": "Total gross area"
  },
  "Property_Building_GrossAreaForHousing": {
    "Name": "Gross area for housing"
  },
  "Property_Building_GrossAreaForOther": {
    "Name": "Gross area for other"
  },
  "Property_Building_HighestFloorCount": {
    "Name": "Highest floor count"
  },
  "Property_Building_HousingUnits": {
    "Name": "Housing units"
  },
  "Property_Building_TotalFloorCount": {
    "Name": "Total floor count"
  },
  "Property_Building_UtilityFloorSpace": {
    "Name": "Total usable area"
  },
  "Property_Building_UtilityFloorSpaceForHousing": {
    "Name": "Usable area for housing"
  },
  "Property_Building_UtilityFloorSpaceForOther": {
    "Name": "Area used for other purposes"
  }
}

export type CadastreBuildingTexts = typeof CADASTRE_BUILDING_TEXTS & RootTexts
export const useCadastreBuildingText = useText<CadastreBuildingTexts>
export default CADASTRE_BUILDING_TEXTS
