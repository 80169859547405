import { useCallback, useState, useEffect, useRef } from "react"
import { get } from "lodash"
import { logError } from "@/v2-ui/utils/utils.log"
import { useSearchParamOpener }
  from "@/v2-console-shared/utils/utils.searchParams"
import { useCadastrePanelLayout }
  from "@/v2-console-shared/cadastre/cadastre.utils"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"
import {
  SIDE_TO_PORTAL_SELECTOR
} from "@/v2-console/cadastre/cadastre.constants"
import { closeAppSidebar }
  from "@/v2-console/app/sidebar/AppSidebar.utils"
import { fetchNavLayerFeaturesFromPoint, useActiveNavLayer }
  from "@/v2-console/map/layer/navLayer/map.layer.navLayer.utils"
import useCadastreQuery, { useCadastreLazyQuery }
  from "@/v2-console/cadastre/cadastre.query"
import { safeJSONParse } from "@/v2-common/utils/utils.misc"
import Point from "@arcgis/core/geometry/Point"

type SerializeCadastrePanelSearchValueInput = {
  cadastreNumber: string,
  point: string | __esri.Point,
  externalIdentifier: string,
  externalSourceCode?: number,
  objectId?: number
}

type CadastrePanelSearchValueSerialized = {
  cadastreNumber: string,
  point: string,
  externalIdentifier: string,
  externalSourceCode?: number,
  objectId?: number
}

export function serializeCadastrePanelSearchParam(input: SerializeCadastrePanelSearchValueInput) {
  const {
    cadastreNumber,
    point,
    externalIdentifier,
    externalSourceCode,
    objectId
  } = input

  const formattedValue: CadastrePanelSearchValueSerialized = {
    cadastreNumber: cadastreNumber?.replaceAll("/", "-"),
    point: typeof point === "string" ? point : encodeURIComponent(JSON.stringify(point)),
    externalIdentifier: externalIdentifier,
    externalSourceCode: externalSourceCode,
    objectId
  }
  return JSON.stringify(formattedValue)
}

export function deserializeCadastrePanelSearchParam(value: string) {
  const parsedValue = safeJSONParse<CadastrePanelSearchValueSerialized>(value)
  if(!parsedValue) return null
  return {
    cadastreNumber: parsedValue.cadastreNumber,
    objectId: parsedValue.objectId,
    externalIdentifier: parsedValue.externalIdentifier,
    externalSourceCode: parsedValue.externalSourceCode,
    point: parsedValue.point ? new Point(JSON.parse(decodeURIComponent(parsedValue.point))) : null
  }
}

export function useCadastrePanel() {
  return useSearchParamOpener("cadastrePanel")
}

/**
 * Extends `useCadastrePanelLayout` with additional functionality:
 *
 * - Synchronizes a portal selector based on the panel's side.
 * - Handles panel resizing and toggling between left and right sides.
 * - Provides a utility to collapse the sidebar on minimize.
 *
 * @returns An object with the current layout state, handlers for side toggling,
 * resizing, and sidebar collapse logic, along with a loading state and portal selector.
 */
export function useCadastrePanelLayoutWithHandlers() {

  const {
    layoutState,
    setLayoutSide,
    setLayoutWidth
  } = useCadastrePanelLayout()

  const { events } = useAppContext()
  const [ portalSelector, setPortalSelector ] = useState<string>()

  useEffect(() => {
    const newSelector = SIDE_TO_PORTAL_SELECTOR[layoutState.side]
    setPortalSelector(newSelector)
  }, [ layoutState ])

  const togglePanelPosition = () => {
    const newSide = layoutState.side === "left" ? "right" : "left"
    setLayoutSide(newSide)
  }

  const onPanelResize = useCallback((width: number) => {
    setLayoutWidth(width)
  }, [ setLayoutWidth ])

  const collapseSidebarOnMinimize = useCallback(() => {
    if(layoutState?.side !== "left") return
    /*
    * @note: using events instead of context so that the caller
    * is not dependent on the isMini and thus need to rerender on call.
    * Such re-renderes disturp resize events, and dont let the caller
    * continue to resize
    */
    closeAppSidebar(events)

  }, [ events, layoutState?.side ])

  return {
    layoutState,
    setLayoutSide,
    onPanelResize,
    isPanelLoading: !layoutState || !portalSelector,
    portalSelector,
    togglePanelPosition,
    collapseSidebarOnMinimize
  }
}

export function useGetCadastreGraphic(
  externalIdentifier: string,
  point: __esri.Point
) {
  const { activeNavLayer } = useActiveNavLayer()
  const [ graphic, setGraphic ] = useState<__esri.Graphic | null>(null)

  useEffect(() => {
    if(!activeNavLayer || !point) {
      setGraphic(null)
      return
    }

    const fetchGraphic = async () => {
      try {
        const graphicCollection = await fetchNavLayerFeaturesFromPoint(point, activeNavLayer)
        if(!graphicCollection) {
          setGraphic(null)
          return
        }

        const foundGraphic = graphicCollection.find((graphic) => {
          if(!activeNavLayer.Configuration || !activeNavLayer.Configuration.externalIdentifier) {
            setGraphic(null)
            return null
          }
          const configExternalIdentifier = activeNavLayer.Configuration.externalIdentifier.split("<")[1].split(">")[0]
          const nativeExternalIdentifier = get(graphic, `attributes.${configExternalIdentifier}`)

          return +externalIdentifier === +nativeExternalIdentifier
        })

        setGraphic(foundGraphic)
      } catch(error) {
        logError(error)
        setGraphic(null)
      }
    }

    fetchGraphic()
  }, [ activeNavLayer, externalIdentifier, point ])

  return graphic
}

type UseCadastreDetailsLazilyProps = {
  cadastreNumber: string
}

/**
 * Hook to lazily fetch cadastre details based on the provided cadastre number.
 * Fetches data only when the `cadastreNumber` changes.
 *
 * @param props - Props containing the `cadastreNumber`.
 * @returns cadastre details
 */
export function useCadastreDetailsLazily(props: UseCadastreDetailsLazilyProps) {
  const { cadastreNumber } = props

  const [ fetchCadastre, { data, loading, error }] = useCadastreLazyQuery()
  const prevCadastreNumberRef = useRef<string | null>(null)

  useEffect(() => {
    if(cadastreNumber !== prevCadastreNumberRef.current) {
      prevCadastreNumberRef.current = cadastreNumber
      fetchCadastre({
        variables: {
          input: {
            cadastreNumber
          }
        }
      })
    }
  }, [ cadastreNumber, fetchCadastre ])

  const cadastreData = data?.getCadastreByCadastreNumber

  return {
    cadastreData,
    cadastreId: cadastreData ? cadastreData.Id : null,
    isLoading: loading,
    error
  }
}

/**
 * Hook to fetch cadastre details immediately on mount based on the provided cadastre number.
 *
 * @param cadastreNumber - The cadastre number to fetch details for.
 * @returns cadastre details
 */
export function useCadastreDetails(cadastreNumber: string) {
  const { data, loading, error } = useCadastreQuery({
    variables: {
      input: {
        cadastreNumber
      }
    }
  })

  return {
    data: data?.getCadastreByCadastreNumber,
    loading,
    error
  }
}
