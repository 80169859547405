import { forwardRef, type ComponentProps } from "react"
import { CommandGroup as CommandGroupBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandGroupProps = ComponentProps<typeof CommandGroupBase>

function CommandGroup(props: CommandGroupProps, ref) {
  const { className, ...commandGroupBaseProps } = props
  return (
    <CommandGroupBase
      ref={ref}
      className={cn("ui-command-group", className)}
      {...commandGroupBaseProps}
    />
  )
}

export default forwardRef(CommandGroup)
