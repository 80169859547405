import commonConfig from "@/v2-common/config"

const config = {
  ...commonConfig,
  userpilotKey: "NX-e5a5c9af",
  gcpKey: "AIzaSyAg46PDPvHviYSV0vNOWLKjT3oQbTOp8WE",
  segmentKey: "4lAQqaNukxxCKCEdsw6cK3nLFD7pE7Go",
  sentryDsn: "https://85fdc4b7f01269976908ae01c397c336@o40672.ingest.us.sentry.io/4507855291482112",
  graphqlUrl: "/graphql",
  authUrl: "/auth-check",
  socketUrl: "ws://localhost:8080/graphql",
  azAuthTenantId: "57fe2dc0-207e-4aa0-b2e6-079db06dc4e9",
  azAuthClientId: "ac6b8323-c6c9-4bcb-97c7-a94dc6ad2ba9",
  azAuthRedirectUrl: "/",
  azAuthPostLogoutRedirectUri: "/",
  docsUrl: "https://docs.placepoint.no",
  geodataServerUrl: "https://services.geodataonline.no/arcgis",
  thirdPartyCredits: "https://placepoint.no/docs/credits"
}

// @todo: the configs from commonConfig doesn't get re-evaluated based on
// these runtime overrides. Should probably turn the commonConfig into a
// function that can merge inn these configs...
if(window.env) {
  if(window.env.ENV_NAME) config.envName = window.env.ENV_NAME
  if(window.env.ENV_TYPE) config.envType = window.env.ENV_TYPE
  if(window.env.VERSION) config.version = window.env.VERSION
  if(window.env.LOG_LEVEL) config.logLevel = window.env.LOG_LEVEL
  if(window.env.SLOT) config.slot = window.env.SLOT
  if(window.env.REGION) {
    config.region = window.env.REGION
    config.isRemote = config.region !== "local"
    config.isLocal = config.region === "local"
    config.isFailover = config.region === "westeu"
  }
}

if(config.isRemote || config.isRemoteAuth) {
  config.authUrl = "https://v2-account-service-next.azurewebsites.net/auth-check"
}

if(config.isRemote || config.isRemoteGraph) {
  config.graphqlUrl = "https://v2-supergraph-next.azurewebsites.net/graphql"
  config.socketUrl = "wss://v2-supergraph-next.azurewebsites.net/graphql"
}

if(config.isProd) {
  config.segmentKey = "AtmMZt8zGcQvGFZUIhpymhieJUPH4FvD"
  config.azAuthClientId = "76e21198-3e4a-4e0a-a691-baa4a98bf32f"
  config.azAuthTenantId = "41139673-f9a9-4b9c-aceb-289c66832c22"
  const { host } = window.location
  const authHost = host.replace("v2-console", "v2-account-service")
  config.authUrl = `https://${authHost}/auth-check`
  const graphqlHost = host.replace("v2-console", "v2-supergraph")
  config.graphqlUrl = `https://${graphqlHost}/graphql`
  config.socketUrl = `wss://${graphqlHost}/graphql`
}

const envConfig = window.env?.ENV_CONFIG || process.env.ENV_CONFIG
if(envConfig) {
  const ec = typeof envConfig === "string"
    ? JSON.parse(envConfig)
    : envConfig
  if(ec.AUTH_URL) config.authUrl = ec.AUTH_URL
  if(ec.SUPERGRAPH_URL) config.graphqlUrl = ec.SUPERGRAPH_URL
  if(ec.SOCKET2_URL) config.socketUrl = ec.SOCKET2_URL
}

export default config
