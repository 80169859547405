import type { ComponentProps } from "react"
import { SheetTrigger as SheetTriggerBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SheetTriggerProps = ComponentProps<typeof SheetTriggerBase>

function SheetTrigger(props: SheetTriggerProps) {
  const { className, ...sheetTriggerBaseProps } = props
  return (
    <SheetTriggerBase
      className={cn("ui-sheet-title", className)}
      {...sheetTriggerBaseProps}
    />
  )
}

export default SheetTrigger
