import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CADASTRE_LISTING_TEXTS, { type CadastreListingTexts }
from "@/v2-console-ui/cadastre/listing/cadastreListing.text"
import type { CadastreModuleContentInputProps }
  from "@/v2-console/cadastre/cadastre.types"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"
import CadastreModuleLoadingFallback
  from "@/v2-console/cadastre/module/CadastreModuleLoadingFallback"

const CadastreListing = lazy(() => lazyRetry(() => import(
  "@/v2-console/cadastre/module/listing/CadastreModuleListing"
)))

type CadastreModuleListingSuspenderProps = CadastreModuleContentInputProps

function CadastreModuleListingSuspender(props: CadastreModuleListingSuspenderProps) {

  return (
    <FeatureProvider<CadastreListingTexts>
      code="UI_CadastreListing"
      fallbackTexts={CADASTRE_LISTING_TEXTS}
      loadingFallback=<CadastreModuleLoadingFallback />
      isLazyLoaded
    >
      <CadastreListing
        {...props}
      />
    </FeatureProvider>
  )
}

export default CadastreModuleListingSuspender
