import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CADASTRE_INDUSTRY_TEXTS, { type CadastreIndustryTexts }
  from "@/v2-console-ui/cadastre/industry/cadastreIndustry.text"
import type { CadastreModuleContentInputProps } from "@/v2-console/cadastre/cadastre.types"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"
import CadastreModuleLoadingFallback
  from "@/v2-console/cadastre/module/CadastreModuleLoadingFallback"

const CadastreIndustry = lazy(() => lazyRetry(() => import(
  "@/v2-console/cadastre/module/industry/CadastreModuleIndustry"
)))

type CadastreModuleIndustrySuspenderProps = CadastreModuleContentInputProps

function CadastreModuleIndustrySuspender(props: CadastreModuleIndustrySuspenderProps) {

  return (
    <FeatureProvider<CadastreIndustryTexts>
      code="Industry_Company"
      fallbackTexts={CADASTRE_INDUSTRY_TEXTS}
      loadingFallback=<CadastreModuleLoadingFallback />
      isLazyLoaded
    >
      <CadastreIndustry
        {...props}
      />
    </FeatureProvider>
  )
}

export default CadastreModuleIndustrySuspender
