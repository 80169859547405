import type { Obj } from "@/v2-common/types"

export type RouteInfo = {
  category: string,
  name: string,
  template?: string,
  search?: string,
  vars?: Obj,
  issueId: number,
  sprintNo: number,
  latestIssueId?: number,
  latestSprintNo?: number
}

export const ROUTES: Record<string, RouteInfo> = {
  SETTING_PANEL: {
    category: "Core",
    name: "SettingPanel",
    template: "/map/user/settings",
    issueId: 0,
    sprintNo: 0
  },
  REDEEM_INVITATION_PAGE: {
    category: "Auth",
    name: "RedeemInvitationPage",
    template: "/redeem/:email/:referral",
    issueId: 0,
    sprintNo: 0
  },
  LOGIN_PAGE: {
    category: "Auth",
    name: "LoginPage",
    template: "/login",
    issueId: 0,
    sprintNo: 0
  },
  LOGOUT_PAGE: {
    category: "Auth",
    name: "LogoutPage",
    template: "/logout",
    issueId: 0,
    sprintNo: 0
  },
  ADDRESS_PANEL: {
    category: "Location",
    name: "AddressPanel",
    template: "/map/address/:addressId",
    issueId: 0,
    sprintNo: 0
  },
  MAP_LAYER_LIST: {
    category: "Map",
    name: "MapLayerList",
    template: "/map/layers",
    issueId: 717,
    sprintNo: 6
  },
  TAG_PANEL: {
    category: "Tags",
    name: "Tags",
    template: "/map/tags",
    issueId: 485,
    sprintNo: 8
  },
  CADASTRE_PANEL: {
    category: "Property",
    name: "CadastrePanel",
    search: "cadastrePanel=",
    issueId: 940,
    sprintNo: 7
  }
}
