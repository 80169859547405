import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol"
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol"
import {
  NAV_LAYER_ID
} from "@/v2-map-ui/map.constants"

export const SYMBOL = new SimpleFillSymbol({
  color: [ 0, 0, 0, 0.01 ],
  outline: new SimpleLineSymbol({
    color: [ 0, 0, 0, 1 ],
    width: "0.5px"
  })
})

export const NAV_LAYER_DEFAULT_MINSCALE = 3000

export const DEFAULT_NAV_LAYER_CONFIG: __esri.GraphicsLayerProperties = {
  id: NAV_LAYER_ID,
  title: "Nav layer",
  minScale: NAV_LAYER_DEFAULT_MINSCALE,
  listMode: "hide"
}

export const NAV_LAYER_DISABLED_ID = "none"

// @note: this is temporary
export const NAV_LAYER_OWNER_URL = "https://www.maanmittauslaitos.fi/en/real-property/information-about-properties/who-owns-property-unit"
