import { useText } from "@/v2-ui/text/text.context"
import { type RootTexts } from "@/v2-console-shared/texts"

const CADASTRE_PROPERTY_TEXTS = {
  "Property_Cadastre_Header": {
    "Name": "Property Details"
  },
  "Property_Cadastre_Number": {
    "Name": "Cadastral number"
  },
  "Property_Cadastre_Border_Area": {
    "Name": "Plot area"
  },
  "Property_Cadastre_StoryCount": {
    "Name": "Storey count"
  },
  "Property_BuiltArea": {
    "Name": "Built-up area"
  }
}

export type CadastrePropertyTexts = typeof CADASTRE_PROPERTY_TEXTS & RootTexts
export const useCadastrePropertyText = useText<CadastrePropertyTexts>
export default CADASTRE_PROPERTY_TEXTS
