import { useEffect, useState, useCallback, useMemo, useRef } from "react"
import dot from "dot-object"
import Color from "@arcgis/core/Color"
import Graphic from "@arcgis/core/Graphic"
import Polygon from "@arcgis/core/geometry/Polygon"
import Point from "@arcgis/core/geometry/Point"
import Polyline from "@arcgis/core/geometry/Polyline"
import Circle from "@arcgis/core/geometry/Circle"
import Extent from "@arcgis/core/geometry/Extent"
import type { Obj } from "@/v2-common/types"
import { randomId } from "@/v2-ui/utils"
import { hasProp } from "@/v2-ui/utils"
import { assertGraphicsLayer }
  from "@/v2-map-ui/layer/mixins/map.layer.mixins.graphicsLayer"
// import { assertFeatureLayer }
//   from "@/v2-map-ui/layer/mixins/map.layer.mixins.featureLayer"
import { WKID } from "@/v2-map-ui/map.constants"
import type {
  ActiveView,
  GraphicSymbol,
  Sketch3DPolygonSymbol,
  Sketch3DPolylineSymbol
} from "@/v2-map-ui/map.types"
import {
  DEFAULT_COLOR,
  DEFAULT_MARKER_ICON_SYMBOL,
  DEFAULT_MARKER_SYMBOL,
  DEFAULT_MUTED_TEXT_SYMBOL,
  DEFAULT_POINT_SYMBOL,
  DEFAULT_POLYGON_SYMBOL,
  DEFAULT_POLYLINE_SYMBOL,
  DEFAULT_TEXT_SYMBOL
} from "@/v2-map-ui/graphic/map.graphic.constants"
import type { ConfigurableFilterLayers } from "@/v2-map-ui/layer/types/map.layer.filter.types"
import { assertSceneLayer } from "@/v2-map-ui/layer/mixins/map.layer.mixins.sceneLayer"
import Collection from "@arcgis/core/core/Collection"
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"

export function assertArcGisColor(color: unknown): color is __esri.Color {
  return hasProp(color, "toRgba")
}

type GeometryType = "polygon"
  | "point"
  | "polyline"
  | "extent"
  | "multipoint"
  | "mesh"

type Attributes = Obj
type Geometry = __esri.GeometryProperties & {
  type: GeometryType
}

export function assertPolygon(g: __esri.Geometry): g is __esri.Polygon {
  return g.type === "polygon"
}
export function assertPoint(g: __esri.Geometry): g is __esri.Point {
  return g.type === "point"
}
export function assertPolyline(g: __esri.Geometry): g is __esri.Polyline {
  return g.type === "polyline"
}
export function assertExtent(g: __esri.Geometry): g is __esri.Extent {
  return g.type === "extent"
}
export function assertCircle(g: __esri.Geometry): g is __esri.Circle {
  return g.type === "polygon" && !!g["center"] && !!g["radius"]
}

export function getCenterPointByGeometry(geometry: __esri.Geometry) {
  if(assertPolygon(geometry)) {
    return geometry.centroid
  }
  if(assertPoint(geometry)) {
    return geometry
  }
  if(assertPolyline(geometry)) {
    return geometry.extent.center
  }
  if(assertExtent(geometry)) {
    return geometry.center
  }
  return null
}

export function assertSketcherPolygon3DSymbol(
  symbol: __esri.Symbol
): symbol is Sketch3DPolygonSymbol {
  if(!symbol) return false
  return symbol.type === "polygon-3d"
}

export function assertSketcherPolyline3DSymbol(
  symbol: __esri.Symbol
): symbol is Sketch3DPolylineSymbol {
  if(!symbol) return false
  return symbol.type === "line-3d"
}

export function assertSimpleFillSymbol(
  symbol: __esri.Symbol
): symbol is __esri.SimpleFillSymbol {
  if(!symbol) return false
  return symbol.type === "simple-fill"
}

export function assertSimpleLineSymbol(
  symbol: __esri.Symbol
): symbol is __esri.SimpleLineSymbol {
  if(!symbol) return false
  return symbol.type === "simple-line"
}

export function assertSimpleMarkerSymbol(
  symbol: __esri.Symbol
): symbol is __esri.SimpleMarkerSymbol {
  if(!symbol) return false
  return symbol.type === "simple-marker"
}

export function assertMeshSymbol3D(
  symbol: __esri.Symbol
): symbol is __esri.MeshSymbol3D {
  if(!symbol) return false
  return symbol.type === "mesh-3d"
}

export function assertSimpleRenderer(
  renderer: __esri.Renderer
): renderer is __esri.SimpleRenderer {
  if(!renderer?.type) return false
  return renderer.type === "simple"
}

export function assertUniqueValueRenderer(
  renderer: __esri.Renderer
): renderer is __esri.UniqueValueRenderer {
  if(!renderer?.type) return false
  return renderer.type === "unique-value"
}

export function assertClassBreaksRenderer(
  renderer: __esri.Renderer
): renderer is __esri.ClassBreaksRenderer {
  if(!renderer?.type) return false
  return renderer.type === "class-breaks"
}

export function assertDictionaryRenderer(
  renderer: __esri.Renderer
): renderer is __esri.DictionaryRenderer {
  if(!renderer?.type) return false
  return renderer.type === "dictionary"
}

export function assertDotDensityRenderer(
  renderer: __esri.Renderer
): renderer is __esri.DotDensityRenderer {
  if(!renderer?.type) return false
  return renderer.type === "dot-density"
}

export function assertHeatmapRenderer(
  renderer: __esri.Renderer
): renderer is __esri.HeatmapRenderer {
  if(!renderer?.type) return false
  return renderer.type === "heatmap"
}

export function assertPieChartRenderer(
  renderer: __esri.Renderer
): renderer is __esri.PieChartRenderer {
  if(!renderer?.type) return false
  return renderer.type === "pie-chart"
}

export function assertColorVariable(
  visualVariable: __esri.VisualVariable
): visualVariable is __esri.ColorVariable {
  if(!visualVariable?.type) return false
  return visualVariable.type === "color"
}

export function assertSizeVariable(
  visualVariable: __esri.VisualVariable
): visualVariable is __esri.SizeVariable {
  if(!visualVariable?.type) return false
  return visualVariable.type === "size"
}

export const createSymbolByType = (
  type: Omit<GeometryType, "extent">,
  symbol: __esri.SymbolProperties
) => {
  switch(type) {
    case "point": {
      return {
        ...DEFAULT_POINT_SYMBOL,
        ...symbol
      }
    }
    case "polyline": {
      return {
        ...DEFAULT_POLYLINE_SYMBOL,
        ...symbol
      }
    }
    case "polygon": {
      return {
        ...DEFAULT_POLYGON_SYMBOL,
        ...symbol
      }
    }
    default: {
      return null
    }
  }
}

export const createGeometryByType = (
  type: GeometryType,
  geometry: __esri.GeometryProperties
) => {
  switch(type) {
    case "point": {
      return new Point(geometry)
    }
    case "polyline": {
      return new Polyline(geometry)
    }
    case "polygon": {
      return new Polygon(geometry)
    }
    case "extent": {
      return new Extent(geometry)
    }
    default: {
      return null
    }
  }
}

export type GraphicCreateInput = {
  geometry: Geometry,
  symbol?: __esri.SymbolProperties,
  attributes?: Attributes
}

export function createGraphic(input: GraphicCreateInput) {
  if(!input) return null
  const { geometry, symbol, attributes } = input
  if(!geometry) return null
  const { type } = geometry
  return new Graphic({
    geometry: createGeometryByType(type, geometry),
    symbol: createSymbolByType(type, symbol),
    attributes
  })
}

export function finalizeGeometryProperties(properties: (
  __esri.PointProperties |
  __esri.PolygonProperties |
  __esri.PolylineProperties |
  __esri.CircleProperties
  // __esri.ExtentProperties |
  // __esri.MultipointProperties |
  // __esri.MeshProperties
)) {
  const commonProperties = {
    spatialReference: { wkid: WKID.ui }
  }
  if(hasProp(properties, "x") && hasProp(properties, "y")) {
    return new Point({ ...commonProperties, ...properties })
  }
  if(hasProp(properties, "rings")) {
    return new Polygon({ ...commonProperties, ...properties })
  }
  if(hasProp(properties, "paths")) {
    return new Polyline({ ...commonProperties, ...properties })
  }
  if(hasProp(properties, "center")) {
    const circle = properties as __esri.CircleProperties
    if(!circle.center.x || !circle.center.y) return null
    return new Circle({
      ...circle,
      center: {
        ...circle.center,
        ...commonProperties
      }
    })
  }
  return null
}

const convertPolyGraphicToPointGraphic = (
  graphic: __esri.Graphic
) => {
  const { attributes, symbol, geometry } = graphic

  if(geometry.type === "polygon") {
    const centroid = (geometry as __esri.Polygon).centroid
    return new Graphic({
      geometry: centroid,
      symbol: createSymbolByType("polygon", symbol),
      attributes
    })
  }

  if(geometry.type === "polyline") {
    const center = (geometry as __esri.Polyline).extent.center
    return new Graphic({
      geometry: center,
      symbol: createSymbolByType("polyline", symbol),
      attributes
    })
  }

  return graphic
}

export type ExtractGraphicOptions = {
  keys?: string[],
  attributes?: __esri.GraphicProperties["attributes"],
  symbol?: __esri.SymbolProperties,
  transform?: (input: __esri.GraphicProperties) => __esri.GraphicProperties,
  convertToPoints?: boolean
}

export const extractGraphic = <T>(
  input: T,
  coordinatesKey = "Coordinates_25833",
  options?: ExtractGraphicOptions
) => {
  const transformProps = options?.transform
    ? options.transform(input)
    : {
      geometry: {},
      attributes: {},
      symbol: null
    }

  const mGeometry = finalizeGeometryProperties({
    ...dot.pick(coordinatesKey, input),
    ...transformProps.geometry
  })

  if(!mGeometry) return null

  const mAttributes = {
    Id: randomId(),
    ...options?.attributes,
    ...transformProps.attributes
  }

  if(options?.keys) {
    for(const key of options.keys) {
      const value = dot.pick(key, input)
      if(!value) continue
      mAttributes[key] = value
    }
  }

  const mSymbol = options?.symbol || transformProps.symbol
    ? {
      ...options?.symbol,
      ...transformProps.symbol
    }
    : null

  const graphic = new Graphic({
    geometry: mGeometry,
    symbol: createSymbolByType(mGeometry.type, mSymbol),
    attributes: mAttributes
  })

  if(options?.convertToPoints) {
    return convertPolyGraphicToPointGraphic(graphic)
  }

  return graphic
}

export function useExtractGraphics<T>(
  input: T | T[],
  coordinatesKey = "Coordinates_25833",
  options?: ExtractGraphicOptions
) {
  return useMemo(() => {
    if(!input || options === null) return []
    if(!Array.isArray(input)) {
      const nGraphic = extractGraphic(input, coordinatesKey, options)
      return nGraphic ? [ nGraphic ] : null
    }

    const graphics: __esri.Graphic[] = []

    for(const i of input) {
      if(!i) continue
      const g = extractGraphic(i, coordinatesKey, options)
      if(!g) continue
      graphics.push(g)
    }

    return graphics
  }, [
    input,
    coordinatesKey,
    options
  ])
}

export function useLayerGraphicsChange(
  layer: __esri.GraphicsLayer | __esri.FeatureLayer,
  cb: (
    graphic: Graphic[],
    e?: __esri.CollectionAfterChangesEvent
  ) => void
) {
  // const gs = useRef<Graphic[]>([])

  useEffect(() => {
    if(!layer || !cb) return
    let h: __esri.Handle

    if(assertGraphicsLayer(layer)) {
      cb(layer.graphics.toArray())
      h = layer.graphics.on("after-changes", (e) => {
        cb(layer.graphics.toArray(), e)
      })
    }

    // if(assertFeatureLayer(layer)) {
    //   (async () => {
    //     const res = await layer.queryFeatures()
    //     gs.current = res.features
    //     cb(res.features)

    //     h = layer.on("edits", (res: any) => {
    //       if(res.edits.addFeatures.length > 0) {
    //         gs.current.push(...res.edits.addFeatures)
    //       }

    //       if(res.edits.deleteFeatures.length > 0) {
    //         const idField = `attributes.${layer.objectIdField}`
    //         const ids = new Set()
    //         for(const f of res.edits.deleteFeatures) {
    //           ids.add(dot.pick(idField, f))
    //         }
    //         gs.current = gs.current.filter((f1) => {
    //           return !ids.has(dot.pick(idField, f1))
    //         })
    //       }

    //       cb(gs.current)
    //     })
    //   })()
    // }

    return () => {
      if(h) h.remove()
    }
  }, [
    layer,
    cb
  ])
}

export function useHasLayerGraphics(
  layer: __esri.GraphicsLayer,
  isImmediate = true
) {
  const [ hasGraphics, setHasGraphics ] = useState<boolean>(null)
  useEffect(() => {
    if(!layer || !isImmediate) return
    setHasGraphics(layer.graphics.length > 0)
  }, [
    isImmediate,
    layer
  ])
  useLayerGraphicsChange(layer, useCallback((graphic) => {
    setHasGraphics(graphic.length > 0)
  }, []))
  return hasGraphics
}

export const useGraphicWatch = (
  graphic: __esri.Graphic,
  path: string | string[],
  cb: __esri.WatchCallback,
  sync?: boolean
) => {
  useEffect(() => {
    if(!graphic || !cb || !path) return
    const watch = graphic.watch(path, cb, sync)
    return () => {
      watch.remove()
    }
  }, [
    path,
    graphic,
    cb,
    sync
  ])
}

export const useGraphicVisibility = (graphic: __esri.Graphic) => {
  const [ isVisible, setIsVisible ] = useState(true)

  useGraphicWatch(graphic, "visible", () => {
    setIsVisible(graphic.visible)
  })

  const toggleVisibility = useCallback((override?: boolean) => {
    if(!graphic) return
    graphic.visible = override ?? !graphic.visible
  }, [
    graphic
  ])

  return {
    isVisible,
    toggleVisibility
  }
}

export const useGraphic = (
  view: __esri.MapView | __esri.SceneView,
  geometry: Geometry,
  symbol?: __esri.GraphicProperties["symbol"],
  attributes: __esri.GraphicProperties["attributes"] = null
) => {
  const [ graphic, setGraphic ] = useState<__esri.Graphic>(null)
  const ready = !!view && !!geometry

  useEffect(() => {
    if(!ready) return
    const newGraphic = createGraphic({
      geometry,
      symbol,
      attributes
    })

    view.graphics.add(newGraphic)
    setGraphic(newGraphic)

    return () => {
      if(!newGraphic) return
      setGraphic(null)
      view.graphics.remove(newGraphic)
      newGraphic.destroy()
    }
  }, [
    ready,
    view,
    geometry,
    symbol,
    attributes
  ])

  const { isVisible, toggleVisibility } = useGraphicVisibility(graphic)

  return {
    graphic,
    isVisible,
    toggleVisibility
  }
}

const filterGraphicsByType = (
  graphics: __esri.Graphic[],
  type: GeometryType
) => {
  return graphics.filter(({ geometry }) => geometry.type === type)
}

export const useGraphicsGeometrySplit = (graphics: __esri.Graphic[]) => {
  return useMemo(() => {
    if(graphics) {
      return {
        points: filterGraphicsByType(graphics, "point"),
        polylines: filterGraphicsByType(graphics, "polyline"),
        polygons: filterGraphicsByType(graphics, "polygon")
      }
    }
    return {
      points: [],
      polylines: [],
      polygons: []
    }
  }, [
    graphics
  ])
}

export type MarkerConfig = {
  view: __esri.MapView | __esri.SceneView,
  geometry: Geometry,
  symbol?: __esri.GraphicProperties["symbol"],
  icon?: __esri.SymbolProperties,
  label1?: __esri.TextSymbolProperties,
  label2?: __esri.TextSymbolProperties
}

export const useMapMarker = (props: MarkerConfig) => {
  const view = props?.view
  const geometry = props?.geometry
  const symbol = props?.symbol || DEFAULT_MARKER_SYMBOL
  const icon = props?.icon || DEFAULT_MARKER_ICON_SYMBOL
  const label1 = props?.label1
  const label2 = props?.label2

  const {
    graphic, isVisible, toggleVisibility
  } = useGraphic(view, geometry, symbol)

  const {
    graphic: iconGraphic
  } = useGraphic(view, geometry, icon)

  const label1Symbol = useMemo(() => {
    if(!label1) return null
    return {
      ...DEFAULT_TEXT_SYMBOL,
      horizontalAlignment: "left",
      xoffset: 17,
      yoffset: 17,
      ...label1
    } as __esri.TextSymbolProperties
  }, [
    label1
  ])

  const {
    graphic: label1Graphic
  } = useGraphic(view, geometry, label1Symbol)

  const label2Symbol = useMemo(() => {
    if(!label2) return null
    return {
      ...DEFAULT_MUTED_TEXT_SYMBOL,
      horizontalAlignment: "left",
      xoffset: 17,
      yoffset: 9,
      ...label2
    }
  }, [
    label2
  ])

  const {
    graphic: label2Graphic
  } = useGraphic(view, geometry, label2Symbol)

  useEffect(() => {
    if(isVisible === undefined || isVisible === null) return
    if(iconGraphic) iconGraphic.visible = isVisible
    if(label1Graphic) label1Graphic.visible = isVisible
    if(label2Graphic) label2Graphic.visible = isVisible
  }, [
    isVisible,
    iconGraphic,
    label1Graphic,
    label2Graphic
  ])

  return {
    marker: graphic,
    isVisible,
    toggleVisibility
  }
}

export type Marker = {
  icon: __esri.Graphic,
  pin: __esri.Graphic
}

export function createMarker(symbol: string, props: GraphicCreateInput) {
  const icon = createGraphic({
    geometry: props.geometry,
    symbol: {
      type: "picture-marker",
      width: 12,
      height: 12,
      yoffset: 20,
      url: symbol
    } as __esri.PictureMarkerSymbolProperties,
    attributes: props.attributes
  })

  const pin = createGraphic({
    geometry: props.geometry,
    symbol: DEFAULT_MARKER_SYMBOL,
    attributes: props.attributes
  })

  const marker: Marker = {
    icon,
    pin
  }

  return marker
}

const randomOffset = (min: number, max: number) => {
  return Math.random() * (min - max) + max
}

export type DispersePointGraphicsArgs = {
  exGraphics: __esri.Graphic[],
  inGraphic: __esri.Graphic,
  min?: number,
  max?: number
}

export const dispersePointGraphics = (
  exGraphics: DispersePointGraphicsArgs["exGraphics"],
  inGraphic: DispersePointGraphicsArgs["inGraphic"],
  min: DispersePointGraphicsArgs["min"] = -0.75,
  max: DispersePointGraphicsArgs["max"] = 0.25
) => {
  if(inGraphic.geometry.type !== "point") return exGraphics
  const inGeometry = inGraphic.geometry as __esri.Point

  return exGraphics.map((exGraphic) => {
    if(exGraphic.geometry.type !== "point") {
      return exGraphic
    }

    const exGeometry = exGraphic.geometry as __esri.Point

    const { x: exX, y: exY } = exGeometry
    const { x: inX, y: inY } = inGeometry

    if(exX === inX && exY === inY) {
      exGraphic.geometry.set("x", exX + randomOffset(min, max))
      exGraphic.geometry.set("y", exY + randomOffset(min, max))
      inGraphic.geometry.set("x", inX - randomOffset(min, max))
      inGraphic.geometry.set("y", inY - randomOffset(min, max))
    }

    return exGraphic
  })
}

export const useDispersePointGraphics = (
  exGraphics: DispersePointGraphicsArgs["exGraphics"],
  min?: DispersePointGraphicsArgs["min"],
  max?: DispersePointGraphicsArgs["max"]
) => {
  return useMemo(() => {
    if(!exGraphics) return []
    let graphics = []
    for(const graphic of exGraphics) {
      graphics = dispersePointGraphics(exGraphics, graphic, min, max)
    }
    return graphics
  }, [
    exGraphics,
    min,
    max
  ])
}

export function getVisualVariablesFromLayer(layer: ConfigurableFilterLayers) {
  if(!assertSceneLayer(layer)) return
  if(assertSimpleRenderer(layer.renderer)) {
    return layer.renderer.visualVariables
  }
  if(assertUniqueValueRenderer(layer.renderer)) {
    return layer.renderer.visualVariables
  }
  if(assertClassBreaksRenderer(layer.renderer)) {
    return layer.renderer.visualVariables
  }
  return
}

export function getColorsFrom3DMeshSymbol(symbol: __esri.Symbol) {
  const collection = new Collection<__esri.Color>()
  if(!assertMeshSymbol3D(symbol)) return collection
  collection.add(symbol.color)
  for(const symbolLayer of symbol.symbolLayers) {
    collection.add(symbolLayer.material.color)
  }
  if(collection.length === 0) return collection
  return collection
}

export function getEdgeSettingsFrom3DMeshSymbol(symbol: __esri.Symbol) {
  const collection = new Collection<__esri.Edges3D>()
  if(!assertMeshSymbol3D(symbol)) return collection
  for(const symbolLayer of symbol.symbolLayers) {
    if(symbolLayer?.edges) {
      collection.add(symbolLayer.edges)
    }
  }
  if(collection.length === 0) return collection
  return collection
}

export function getEdgeColorsFrom3DMeshSymbol(symbol: __esri.Symbol) {
  const collection = new Collection<__esri.Color>()
  const edgeCollection = getEdgeSettingsFrom3DMeshSymbol(symbol)
  if(!edgeCollection || edgeCollection.length === 0) return collection
  for(const edge of edgeCollection) {
      collection.add(edge.color)
  }
  if(collection.length === 0) return collection
  return collection
}

export const getSymbolStyleType = (style: string) => {
  switch(style) {
    case "polygon-3d": {
      return "polygon-3d"
    }
    case "esriSMS": {
      return "simple-marker"
    }
    case "esriSLS": {
      return "simple-line"
    }
    case "esriSFS": {
      return "simple-fill"
    }
    default: {
      return "simple-fill"
    }
  }
}

type SymbolProperties = GraphicSymbol
  | Sketch3DPolygonSymbol
  | Sketch3DPolylineSymbol
  | __esri.SimpleLineSymbol

export function getSymbolProperties(symbol: SymbolProperties) {
  const defaultColor = new Color("grey").toRgba()

  if(assertSketcherPolygon3DSymbol(symbol)) {
    return {
      type: getSymbolStyleType(symbol.type),
      color: symbol.symbolLayers.at(0).material?.color?.toRgba() || defaultColor,
      outline: {
        type: "solid", //symbol.symbolLayers.at(0).edges.type,
        color: symbol.symbolLayers.at(0).edges?.color?.toRgba() || defaultColor,
        width: 1
      }
    }
  } else if(assertSketcherPolyline3DSymbol(symbol)) {
    return {
      type: getSymbolStyleType(symbol.type),
      color: [ 0, 0, 0, 0 ],
      outline: {
        type: "solid",
        color: symbol.symbolLayers.at(0).material?.color?.toRgba() || defaultColor,
        width: 10
      }
    }
  } else if(assertSimpleLineSymbol(symbol)) {
    return {
      type: getSymbolStyleType(symbol.type),
      color: [ 0, 0, 0, 0 ],
      outline: {
        type: "solid",
        color: symbol.color?.toRgba() || defaultColor,
        width: 10
      }
    }
  }
  else
    return {
      type: getSymbolStyleType(symbol.type),
      color: symbol.color.toRgba(),
      outline: symbol.outline
        ? {
          type: getSymbolStyleType(symbol.outline.type),
          color: symbol.outline.color.toRgba(),
          width: 1
        }
        : null
    }
}

export function getSymbolJsonProperties(symbol) {
  const { color, outline } = symbol
  const out = {
    type: getSymbolStyleType(symbol.type),
    color: {
      r: color[0] || color.r,
      g: color[1] || color.g,
      b: color[2] || color.b,
      a: color[3] || color.a
    },
    outline: null
  }
  if(outline) {
    const outlineColor = outline.color
    out.outline = {
      type: "simple-line",
      width: outline.width || 1,
      color: {
        r: outlineColor[0] || outlineColor.r,
        g: outlineColor[1] || outlineColor.g,
        b: outlineColor[2] || outlineColor.b,
        a: outlineColor[3] || outlineColor.a
      }
    }
  }
  return out
}

export const getSymbolColour = (
  color: string | number[] | Record<"r" | "g" | "b" | "a", number>,
  alpha?: number
): string => {
  if(!color) return ""
  if(Array.isArray(color)) {
    if(color.length < 4) return `rgba(${color.join(",")},${alpha || 0.2})`
    return `rgba(${color.join(",")})`
  }
  if(typeof color === "string") return color
  let { r, g, b, a } = color
  if(!r) r = DEFAULT_COLOR.r
  if(!g) g = DEFAULT_COLOR.g
  if(!b) b = DEFAULT_COLOR.b
  if(!a) a = 0.2
  if(alpha) a = alpha
  return `rgba(${r},${g},${b},${a})`
}

export const getSymbol = (type: "point" | "linestring" | "polygon"): (
  __esri.SimpleMarkerSymbolProperties |
  __esri.SimpleFillSymbolProperties |
  __esri.SimpleLineSymbolProperties
) => {
  if(type.toLowerCase() === "point") {
    return DEFAULT_POINT_SYMBOL
  }
  if(type.toLowerCase() === "linestring") {
    return DEFAULT_POLYLINE_SYMBOL
  }
  return DEFAULT_POLYGON_SYMBOL
}

const drawCircle = (start: __esri.Point, end: __esri.Point) => {
  return new Graphic({
    geometry: new Circle({
      radiusUnit: "meters",
      center: start,
      radius: Math.floor(
        Math.sqrt(
          Math.pow(start.x - end.x, 2)
          + Math.pow(start.y - end.y, 2)
        )
      )
    }),
    symbol: {
      type: "simple-fill",
      color: [ 222, 41, 0, 0.25 ],
      outline: {
        width: 2,
        color: [ 222, 41, 0, 0.5 ]
      }
    } as __esri.SimpleFillSymbolProperties
  })
}

export const useDrawCircleTool = (
  view: __esri.MapView | __esri.SceneView,
  cb: (x: number, y: number, r: number) => void
): (isActive?: boolean) => void => {
  const [ isActive, setIsActive ] = useState(false)

  useEffect(() => {
    if(!view || !cb) return
    let onPointerDown = null

    if(isActive) {
      onPointerDown = view.on("pointer-down", (pe: __esri.ViewPointerDownEvent) => {
        let circle: __esri.Graphic = null

        const onDrag = view.on("drag", (de: __esri.ViewDragEvent) => {
          de.stopPropagation()
          if(circle) view.graphics.remove(circle)
          circle = drawCircle(
            view.toMap({ x: pe.x, y: pe.y }),
            view.toMap({ x: de.x, y: de.y })
          )
          view.graphics.add(circle)
        })

        let onPointerUp = view.on("pointer-up", () => {
          if(circle) {
            const { center, radius } = circle.geometry as __esri.Circle
            const { x, y } = center
            cb(x, y, radius)
            view.graphics.remove(circle)
            circle = null
          }
          onDrag.remove()
          onPointerUp.remove()
          onPointerUp = null
          setIsActive(false)
        })
      })
    }

    return () => {
      if(!onPointerDown) return
      onPointerDown.remove()
      onPointerDown = null
    }
  }, [
    view,
    isActive,
    cb
  ])

  return useCallback((isActive = true) => {
    setIsActive(isActive)
  }, [])
}

export const drawRect = (
  start: __esri.Point,
  end: __esri.Point,
  props?: __esri.GraphicProperties
) => {
  return new Graphic({
    geometry: Polygon.fromExtent(new Extent({
      ymax: start.y,
      xmax: start.x,
      ymin: end.y,
      xmin: end.x,
      spatialReference: {
        wkid: WKID.ui
      }
    })),
    symbol: {
      type: "simple-fill",
      color: [ 222, 41, 0, 0.25 ],
      outline: {
        width: 1,
        color: [ 222, 41, 0, 0.5 ]
      }
    } as __esri.SimpleFillSymbolProperties,
    ...props
  })
}

export function useDrawRectTool(
  view: __esri.MapView | __esri.SceneView,
  onPointerUp?: (rect: __esri.Graphic, e: __esri.ViewPointerUpEvent) => void,
  onDrag?: (rect: __esri.Graphic, e: __esri.ViewDragEvent) => void,
  onPointerDown?: (e: __esri.ViewPointerDownEvent) => void
) {
  const [ isActive, setIsActive ] = useState(false)

  useEffect(() => {
    if(!view || !onPointerUp) return
    let pd: IHandle = null

    if(isActive) {
      pd = view.on("pointer-down", (pe: __esri.ViewPointerDownEvent) => {
        let rect: __esri.Graphic = null

        if(onPointerDown) onPointerDown(pe)

        let dg = view.on("drag", (de: __esri.ViewDragEvent) => {
          de.stopPropagation()
          if(rect) view.graphics.remove(rect)
          rect = drawRect(
            view.toMap({ x: pe.x, y: pe.y }),
            view.toMap({ x: de.x, y: de.y })
          )
          view.graphics.add(rect)
          if(onDrag) onDrag(rect, de)
        })

        let pu = view.on("pointer-up", (e) => {
          if(rect) {
            if(onPointerUp) onPointerUp(rect, e)
            view.graphics.remove(rect)
            rect = null
          }
          dg.remove()
          dg = null
          pu.remove()
          pu = null
          setIsActive(false)
        })
      })
    }

    return () => {
      if(!pd) return
      pd.remove()
      pd = null
    }
  }, [
    view,
    isActive,
    onPointerUp,
    onDrag,
    onPointerDown
  ])

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  return useCallback((isActive: boolean = true) => {
    setIsActive(isActive)
  }, [])
}

type CreateRectAtScreenPointOptions = {
  x: number,
  y: number,
  height: number,
  width: number
}

export function createRectAtScreenPoint(
  view: ActiveView,
  options: CreateRectAtScreenPointOptions,
  polygonProps?: __esri.PolygonProperties
) {
  const { x, y, height, width } = options

  const ymax = y + (height / 2)
  const ymin = y - (height / 2)
  const xmax = x + (width / 2)
  const xmin = x - (width / 2)

  const tl = view.toMap({ y: ymax, x: xmin })
  const tr = view.toMap({ y: ymax, x: xmax })
  const bl = view.toMap({ y: ymin, x: xmin })
  const br = view.toMap({ y: ymin, x: xmax })

  if(!tl || !tr || !bl || !br) return null

  return new Polygon({
    rings: [[
      [ tl.x, tl.y ],
      [ tr.x, tr.y ],
      [ br.x, br.y ],
      [ bl.x, bl.y ],
      [ tl.x, tl.y ]
    ]],
    spatialReference: {
      wkid: WKID.ui
    },
    ...polygonProps
  })
}

export function getEnvelopeAtPoint(
  x: number,
  y: number,
  size: number
) {
  return {
    ymax: y + (size / 2),
    ymin: y - (size / 2),
    xmax: x + (size / 2),
    xmin: x - (size / 2)
  }
}

const TILE_WIDTH = 101
const TILE_HEIGHT = 101
const ENVELOPE_SIZE = 10

export function getWmsFeatureInfoInput(x: number, y: number) {
  return {
    i: 50,
    j: 50,
    width: TILE_WIDTH,
    height: TILE_HEIGHT,
    ...getEnvelopeAtPoint(x, y, ENVELOPE_SIZE)
  }
}

// Function to check if a point is inside the extent
export function isPointInsideExtent(point: __esri.Point, extent: __esri.Extent) {
  return (
    point.x >= extent.xmin
      && point.x <= extent.xmax
      && point.y >= extent.ymin
      && point.y <= extent.ymax
  )
}

type UseGraphicHighlightInput = {
  view: ActiveView,
  layer: __esri.GraphicsLayer,
  highlightedSymbol: (existingSymbol: SimpleMarkerSymbol) => __esri.SimpleMarkerSymbolProperties
}
/**
 * Provides a way to highlight a graphic in a graphics layer. The graphic will be highlighted with a new symbol.
 * @param input - The input object.
 * @returns An object with functions to highlight and reset the highlighted graphic.
*/
export function useGraphicHighlight(input: UseGraphicHighlightInput) {
  const { view, layer, highlightedSymbol } = input
  const originalSymbols = useMemo(() => new WeakMap<__esri.Graphic, __esri.Symbol>(), [])
  const hoveredGraphic = useRef<__esri.Graphic>(null)

  // Logic to highlight a graphic
  const highlightGraphic = useCallback((graphic: __esri.Graphic) => {
    if(hoveredGraphic.current === graphic) return

    if(assertSimpleMarkerSymbol(graphic.symbol)) {
      const clonedSymbol = graphic.symbol.clone()
      // Store the original symbol if it's not already stored
      if(!originalSymbols.has(graphic)) {
        originalSymbols.set(graphic, clonedSymbol)
      }

      // @Note: we need to clone the symbol to avoid changing the original symbol
      // we think this is the best solution for now.
      graphic.symbol = new SimpleMarkerSymbol(highlightedSymbol(clonedSymbol.clone()))
      hoveredGraphic.current = graphic

      if(graphic.attributes.count === 1) {
        const graphicsIndex = layer.graphics.indexOf(graphic)
        graphic.attributes.order = graphicsIndex
        if(assertSimpleMarkerSymbol(clonedSymbol)) {
          moveGraphicToTop(layer, hoveredGraphic.current)
        }
      }
    } else {
      throw new Error("Symbol is not a SimpleMarkerSymbol")
    }

  }, [ highlightedSymbol, originalSymbols, layer ])

  // Logic to reset highlighted graphic
  const resetHighlightedGraphic = useCallback((graphic: __esri.Graphic) => {
    if(!originalSymbols.has(graphic) || !view || !layer) return

    const originalSymbol = originalSymbols.get(graphic)
    graphic.symbol = originalSymbol
    originalSymbols.delete(graphic)
    hoveredGraphic.current = null
    view.container.style.cursor = "default"

    // checks if null or undefined. Cant use not operator because 0 would be false
    if(graphic.attributes?.order !== null && graphic.attributes?.order !== undefined) {
      layer.graphics.reorder(graphic, graphic.attributes.order)
    }

  }, [ originalSymbols, view, layer ])

  return { highlightGraphic, resetHighlightedGraphic, originalSymbols, hoveredGraphic }
}

export type UseGraphicsLayerGraphicHoverEffectInput = UseGraphicHighlightInput & {
  isDisabled: boolean
}
/**
 * Applies a hover effect to graphics in a graphics layer based on pointer movement in the active view.
 * @param input - The input object.
 * @returns An object with a function to reset the highlighted graphic.
*/

export function useApplyHoverStyleToGraphicInGraphicsLayer(
  input: UseGraphicsLayerGraphicHoverEffectInput
) {
  const { view, isDisabled, layer, highlightedSymbol } = input
  const layerId = useRef<string>(null)

  const {
    highlightGraphic,
    resetHighlightedGraphic,
    originalSymbols,
    hoveredGraphic
  } = useGraphicHighlight({
    view,
    layer,
    highlightedSymbol
  })

  useEffect(() => {
    if(!view || !layer || isDisabled) return
    layerId.current = layer.id

    const handlePointerMove = createGraphicHitTestHandler({
      view,
      layerId: layerId.current,
      resolve: (graphic) => {
        if(graphic) {
          if(hoveredGraphic.current && hoveredGraphic.current !== graphic) {
            resetHighlightedGraphic(hoveredGraphic.current)
          }

          if(!originalSymbols.has(graphic) && assertSimpleMarkerSymbol(graphic.symbol)) {
            originalSymbols.set(graphic, graphic.symbol.clone())
          }

          highlightGraphic(graphic)
          view.container.style.cursor = "pointer"
        } else if(hoveredGraphic) {
          resetHighlightedGraphic(hoveredGraphic.current)
          hoveredGraphic.current = null
        }
      },
      hitCondition: (result) => {
        return assertSimpleMarkerSymbol(result.graphic.symbol)
      }
    })

    const pointerMoveEvent = view.on("pointer-move", handlePointerMove)

    const leaveEvent = view.on("pointer-leave", () => {
      if(hoveredGraphic) {
        resetHighlightedGraphic(hoveredGraphic.current)
        hoveredGraphic.current = null
      }
    })

    const zoomEvent = view.watch("zoom", () => {
      if(hoveredGraphic) {
        resetHighlightedGraphic(hoveredGraphic.current)
        hoveredGraphic.current = null
      }
    })

    return () => {
      pointerMoveEvent.remove()
      leaveEvent.remove()
      zoomEvent.remove()
    }
  }, [
    highlightedSymbol,
    isDisabled,
    layer,
    originalSymbols,
    resetHighlightedGraphic,
    view,
    hoveredGraphic,
    highlightGraphic
  ])

  return {
    resetHighlightedGraphic: useCallback(() => {
      if(hoveredGraphic) {
        resetHighlightedGraphic(hoveredGraphic.current)
        hoveredGraphic.current = null
      }
    }, [ resetHighlightedGraphic, hoveredGraphic ])
  }
}

export function moveGraphicToTop(layer: __esri.GraphicsLayer, graphic: __esri.Graphic) {
  const maxIndex = layer.graphics.length - 1
  const graphicIndex = layer.graphics.indexOf(graphic)
  if(graphicIndex === maxIndex) return
  layer.graphics.reorder(graphic, maxIndex)
}

type createGraphicHitTestHandlerInput = {
  view: ActiveView,
  layerId: string,
  resolve: (value: __esri.Graphic | null) => void,
  reject?: (reason?: any) => void,
  hitCondition?: (result: __esri.MapViewGraphicHit) => boolean

}
/**
 * Creates a hit test handler function that performs a hit test on the given view and layer ID.
 * The handler resolves with the first graphic that matches the layer ID and has a simple marker symbol.
 * If no matching graphic is found, the handler resolves with null.
 * If an error occurs during the hit test, the handler rejects with the error.
 * @param input - The input object.
 * @returns The hit test handler function.
 */
function createGraphicHitTestHandler(input: createGraphicHitTestHandlerInput) {
  const { view, layerId, resolve, reject, hitCondition } = input
  return async (e: __esri.ViewPointerMoveEvent) => {
    try {
      const res: __esri.HitTestResult = await view.hitTest(e)
      const hitResult = res.results.find((result) =>
        assertMapViewViewHitIsGraphic(result)
        && result.graphic
        && result.graphic.layer
        && result.graphic.layer.id === layerId
        && (!hitCondition || hitCondition(result)) // Use the hitCondition here
      )

      if(hitResult && assertMapViewViewHitIsGraphic(hitResult)) {
        const graphic = hitResult.graphic
        resolve(graphic)
      } else {
        resolve(null)
      }
    } catch(error) {
      if(reject) {
        reject(error)
      }
    }
  }
}

export function assertMapViewViewHitIsGraphic(mapViewHitResult: __esri.MapViewViewHit): mapViewHitResult is __esri.MapViewGraphicHit {
  return mapViewHitResult.type === "graphic"
}

/**
 * Returns the highlighted graphic from a specific layer based on the pointer movement in the active view.
 * @param view - The active view object.
 * @param layerId - The ID of the layer to perform the hit test on.
 * @returns The highlighted graphic from the specified layer.
 */
export function useHighlightedGraphicFromLayer(
  view: ActiveView,
  layerId: string
) {
  const [ highlightedGraphic, setHighlightedGraphic ] = useState<__esri.Graphic>(null)

  useEffect(() => {
    if(!view || !layerId) return

    const handlePointerMove = createGraphicHitTestHandler({
      view,
      layerId,
      resolve: (graphic) => {
        if(graphic && !highlightedGraphic) {
          setHighlightedGraphic(graphic)
        } else if(!graphic && highlightedGraphic) {
          setHighlightedGraphic(null)
        }
      }
    })

    const h: __esri.Handle = view.on("pointer-move", handlePointerMove)

    return () => {
      if(h) h.remove()
    }
  }, [ view, layerId, highlightedGraphic ])

  return highlightedGraphic
}
