import type { ComponentProps } from "react"
import type { CustomIcons } from "@/v2-ui/icon/custom/icon.custom.types"
import IconCustomCompass from "@/v2-ui/icon/custom/compass/IconCustomCompass"
import IconCustomViewshed from "@/v2-ui/icon/custom/viewshed/IconCustomViewshed"

export type IconCustomProps = ComponentProps<"svg"> & {
  src: CustomIcons
}
const customIconSrcComponent = {
  "compass": IconCustomCompass,
  "viewshed": IconCustomViewshed
}
function IconCustom(props: IconCustomProps) {
  const {
    src,
    ...svgProps
  } = props

  const IconCustomSrc = customIconSrcComponent[src]

  return <IconCustomSrc {...svgProps} />
}

export default IconCustom
