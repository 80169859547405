import type { ComponentProps } from "react"
import { SheetTitle as SheetTitleBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"
import ConditionalWrapper
  from "@/v2-ui/ConditionalWrapper"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"

export type SheetTitleProps = ComponentProps<typeof SheetTitleBase> & {
  isVisuallyHidden?: boolean
}

function SheetTitle(props: SheetTitleProps) {
  const { isVisuallyHidden, className, ...sheetTitleBaseProps } = props
  return (
    <ConditionalWrapper
      condition={isVisuallyHidden}
      renderWrapper={(children) => (
        <VisuallyHidden.Root>
          {children}
        </VisuallyHidden.Root>
      )}
    >
      <SheetTitleBase
        className={cn("ui-sheet-title", className)}
        {...sheetTitleBaseProps}
      />
    </ConditionalWrapper>

  )
}

export default SheetTitle
