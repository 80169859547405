import { cva } from "class-variance-authority"

/**
 * Badge core variants configuration.
 *
 * This configuration defines various badge styles.
 *
 * Variants:
 * - `variant`:
 * - `default`: Default badge style with background and text color.
 * - `secondary`: Secondary badge style with background and text color.
 * - `destructive`: Destructive badge style with background and text color.
 * - `outline`: Badge with border and text color.
 *
 * Default Variants:
 * - `variant`: `default`
 */
export const badgeCoreVariants = cva(
  "",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground",
        noBackground: "border-transparent text-primary-foreground shadow"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
)
