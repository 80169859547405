import { useSearchParams } from "@/v2-console-shared/utils/utils.router"

export type ExtraParams = Record<string, string>
export type NavOptions = {
  replace?: boolean,
  state?: object
}

export function useSearchParamOpener(key: string) {
  const [ searchParams, setSearchParams ] = useSearchParams()
  const activeValue = searchParams.get(key)

  function getIsActive(value: string | number) {
    return activeValue === String(value)
  }

  function open(
    value: string | number = "1",
    extra?: ExtraParams,
    options?: NavOptions
  ) {
    setSearchParams({
      [key]: String(value),
      ...extra
    }, options)
  }

  function close(extra?: ExtraParams) {
    setSearchParams({
      [key]: null,
      ...extra
    })
  }

  /**
   * Boolean returned represents the isActive state _after_
   * the value was toggled
   */
  function toggle(
    value: string | number = activeValue,
    extra?: ExtraParams,
    options?: NavOptions
  ) {
    const isActive = getIsActive(value)
    isActive
      ? close(extra)
      : open(value ?? "1", extra, options)
    return !isActive
  }

  return {
    searchParams,
    setSearchParams,
    isActive: !!activeValue,
    activeValue,
    getIsActive,
    open,
    close,
    toggle
  }
}
