import { useText } from "@/v2-ui/text/text.context"
import { type RootTexts } from "@/v2-console-shared/texts"

const CADASTRE_LISTING_TEXTS = {
  "UI_CadastreListing_Header": {
    "Name": "Listing",
    "Description": "Listing header text"
  },
  "UI_CadastreListing_Details": {
    "Name": "Details",
    "Description": "Details button text"
  },
  "UI_CadastreListing_Empty_Image": {
    "Name": "No image found...",
    "Description": "Empty image message"
  },
  "UI_CadastreListing_Tab_Sales": {
    "Name": "Sales",
    "Description": "Sales tab title"
  },
  "UI_CadastreListing_Tab_Rental": {
    "Name": "Rental",
    "Description": "Rental tab title"
  },
  "UI_CadastreListing_Empty": {
    "Name": "No data found...",
    "Description": "Empty data message"
  },
  "UI_CadastreListing_SeeLess": {
    "Name": "See less",
    "Description": "See less images"
  }
}

export type CadastreListingTexts = typeof CADASTRE_LISTING_TEXTS & RootTexts
export const useCadastreListingText = useText<CadastreListingTexts>
export default CADASTRE_LISTING_TEXTS
