import type { LayerType } from "@/v2-map-ui/map.types"
import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"

export type OGCFeatureLayerProps = WithMeta<__esri.OGCFeatureLayerProperties>
export type OGCFeatureLayer = WithMeta<__esri.OGCFeatureLayer>

export function assertOGCFeatureLayer(
  layer: __esri.Layer
): layer is __esri.OGCFeatureLayer {
    return layer.type === "ogc-feature"
}

export function assertOGCFeatureLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is OGCFeatureLayerProps {
  return type === "ogc-feature"
}
