import type { LayerType } from "@/v2-map-ui/map.types"
import type { WithMeta } from "@/v2-map-ui/layer/mixins/map.layer.mixins.types"

export type ImageryLayerProps = WithMeta<__esri.ImageryLayerProperties>
export type ImageryLayer = WithMeta<__esri.ImageryLayer>

export function assertImageryLayer(
  layer: __esri.Layer
): layer is ImageryLayer {
  return layer.type === "imagery"
}

export function assertImageryLayerPropsByType(
  type: LayerType,
  props: __esri.LayerProperties
): props is ImageryLayerProps {
  return type === "imagery"
}
